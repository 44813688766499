import React, { useEffect } from "react";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const itemsPerPage = 10;

export const UserCopyTrade = () => {
  const [Page, setPage] = useState(1);
  const orders = useSelector((state) => state.orders);
  const authedUser = useSelector((state) => state.authedUser);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const [filteredOrders, setFilteredOrders] = useState(
    orders.orders
      ?.filter((ord) =>
        startDate
          ? new Date(startDate).getTime() < new Date(ord.time).getTime()
          : true
      )
      ?.filter((ord) =>
        endDate
          ? new Date(endDate).getTime() > new Date(ord.time).getTime()
          : true
      )
  );
  const currentOrders = filteredOrders.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(filteredOrders.length / itemsPerPage);

  const follower = authedUser.authedUser.master
    ? []
    : ["Take Profit", "Stop Loss"];
  const followerObj = (order) =>
    authedUser.authedUser.master
      ? {}
      : {
          "Take Profit": parseFloat(order?.stopPrice)
            ? parseFloat(order?.price).toFixed(2)
            : "-",
          "Stop Loss": parseFloat(order?.stopPrice)
            ? parseFloat(order?.stopPrice).toFixed(2)
            : "-",
        };

  const getOrderProfit = (order) => {
    if (order.closedFor) {
      const buyOrder = orders.orders.find((o) => o._id === order.closedFor);
      if (buyOrder) {
        const buyPrice =
          parseFloat(buyOrder?.cummulativeQuoteQty) &&
          parseFloat(buyOrder?.origQty)
            ? parseFloat(buyOrder?.cummulativeQuoteQty / buyOrder?.origQty)
            : 0;
        const sellPrice =
          parseFloat(order?.cummulativeQuoteQty) && parseFloat(order?.origQty)
            ? parseFloat(order?.cummulativeQuoteQty / order?.origQty)
            : 0;
        if (sellPrice && buyPrice) {
          return (
            <div
              className="volume text-uppercase"
              style={{ color: sellPrice > buyPrice ? "green" : "red" }}
            >
              {(
                ((sellPrice > buyPrice
                  ? sellPrice - buyPrice
                  : buyPrice - sellPrice) /
                  buyPrice) *
                100
              )
                .toFixed(4)
                .toString()}
              %
            </div>
          );
        } else {
          return (
            <div className="volume text-uppercase" style={{ color: "white" }}>
              -
            </div>
          );
        }
      } else {
        return (
          <div className="volume text-uppercase" style={{ color: "white" }}>
            -
          </div>
        );
      }
    } else {
      return (
        <div className="volume text-uppercase" style={{ color: "white" }}>
          -
        </div>
      );
    }
  };
  const getOrderProfitExcel = (order) => {
    if (order.closedFor) {
      const buyOrder = orders.orders.find((o) => o._id === order.closedFor);
      if (buyOrder) {
        const buyPrice =
          parseFloat(buyOrder?.cummulativeQuoteQty) &&
          parseFloat(buyOrder?.origQty)
            ? parseFloat(buyOrder?.cummulativeQuoteQty / buyOrder?.origQty)
            : 0;
        const sellPrice =
          parseFloat(order?.cummulativeQuoteQty) && parseFloat(order?.origQty)
            ? parseFloat(order?.cummulativeQuoteQty / order?.origQty)
            : 0;
        if (sellPrice && buyPrice) {
          return `${(
            ((sellPrice > buyPrice
              ? sellPrice - buyPrice
              : buyPrice - sellPrice) /
              buyPrice) *
            100
          )
            .toFixed(4)
            .toString()}%`;
        } else {
          return "-";
        }
      } else {
        return "-";
      }
    } else {
      return "-";
    }
  };

  const getOrderRow = (order) => {
    let time = "--:--";
    if (order.time) {
      const d = new Date(order?.time);
      time = `${d.getHours().toString().padStart(2, "0")}:${d
        .getMinutes()
        .toString()
        .padStart(2, "0")} - ${d.getDate()}/${d.getMonth() + 1}/${d
        .getFullYear()
        .toString()
        .substring(2)}`;
    }
    const symbol = `,${order?.symbol}`;
    const type = `,${order?.side} ${
      order?.type === "LIMIT" || order?.type === "MARKET"
        ? `(${order?.type})`
        : "(with TP/SL)"
    }`;
    const trader = `,${
      authedUser?.authedUser?.master
        ? "Self"
        : order?.cancelledBy === authedUser?.authedUser?._id
        ? authedUser?.authedUser?.username
        : order?.master?.username
        ? order?.master?.username
        : "-"
    }`;
    const fOrders = !authedUser?.authedUser?.master
      ? `,${order?.fOrders ? order?.fOrders : "-"}`
      : "";
    const tp = !authedUser?.authedUser?.master
      ? `,${
          parseFloat(order?.stopPrice)
            ? parseFloat(order?.price).toFixed(2)
            : "-"
        }`
      : "";
    const sl = !authedUser?.authedUser?.master
      ? `,${
          parseFloat(order?.stopPrice)
            ? parseFloat(order?.stopPrice).toFixed(2)
            : "-"
        }`
      : "";
    const price = `,${
      order?.type === "STOP_LOSS_LIMIT"
        ? parseFloat(order?.entryPrice).toFixed(4)
        : order?.status === "FILLED"
        ? parseFloat(
            parseFloat(order?.cummulativeQuoteQty) / parseFloat(order?.origQty)
          ).toFixed(4)
        : parseFloat(order?.price).toFixed(4)
    }`;
    const priceExec = `,${
      parseFloat(order?.cummulativeQuoteQty) && parseFloat(order?.origQty)
        ? parseFloat(order?.cummulativeQuoteQty / order?.origQty)
            .toString()
            .substring(0, 7)
        : "-"
    }`;
    const qty = `,${
      // order?.side.toLowerCase() === "buy"
      // ?
      parseFloat(order?.origQty)
        ? `${order?.origQty.toString().substring(0, 7)} ${
            order?.symbol.includes("-")
              ? order?.symbol.split("-")[0]
              : order?.symbol.substring(0, 3)
          }`
        : "-"
      // : `${
      //     parseFloat(order?.cummulativeQuoteQty)
      //       ? order?.cummulativeQuoteQty.toString().substring(0, 7)
      //       : parseFloat(
      //           (parseFloat(order?.origQty) * order?.price).toFixed(4)
      //         )
      //   } ${
      //     order?.symbol.includes("-")
      //       ? order?.symbol.split("-")[1]
      //       : order?.symbol.substring(3)
      //   }`
    }`;

    // ${
    const qtySpent = `,${
      // order?.side.toLowerCase() === "buy"
      // ?
      parseFloat(order?.cummulativeQuoteQty)
        ? `${order?.cummulativeQuoteQty.toString().substring(0, 7)} ${
            order?.symbol.includes("-")
              ? order?.symbol.split("-")[1]
              : order?.symbol.substring(3)
          }`
        : `${parseFloat(
            (parseFloat(order?.origQty) * order?.price).toFixed(4)
          )} ${
            order?.symbol.includes("-")
              ? order?.symbol.split("-")[1]
              : order?.symbol.substring(3)
          }`
      // : `${
      //     parseFloat(order?.origQty)
      //       ? `${order?.origQty.toString().substring(0, 7)} ${
      //           order?.symbol.includes("-")
      //             ? order?.symbol.split("-")[0]
      //             : order?.symbol.substring(0, 3)
      //         }`
      //       : "-"
      //   }`
    }`;

    const statuss = `,${getSt(order.status)}`;
    const profit = `,${getOrderProfitExcel(order)}`;
    // return `${time}${symbol}${type}${trader}${tp}${sl}${price}${priceExec}${qty}${qtySpent}${statuss}`
    return {
      time,
      symbol,
      type,
      trader,
      tp,
      sl,
      price,
      priceExec,
      qty,
      qtySpent,
      statuss,
      profit,
      fOrders,
    };
  };

  const masterObj = (ob) => {
    if (authedUser.authedUser.master) {
      return { "Follower orders": ob.fOrders };
    }
    return {};
  };

  const getOrderObj = (order) => {
    const ob = getOrderRow(order);
    return {
      "Date Time": ob.time,
      Pair: ob.symbol,
      Type: ob.type,
      Trader: ob.trader,
      ...masterObj(ob),
      ...followerObj(order),
      Price: ob.price,
      "Order price executred": ob.priceExec,
      Qty: ob.qty,
      "Qty. spent": ob.qtySpent,
      Status: ob.statuss,
      Profit: ob.profit,
    };
  };

  const getSt = (s) => {
    if (s === "NEW") {
      return "Pending";
    }
    if (s === "FILLED") {
      return "Executed";
    }
    if (s === "CANCELLED") {
      return "Cancelled";
    }
    return "-";
  };

  function downloadCSV() {
    const worksheet = XLSX.utils.json_to_sheet(
      orders.orders.map((order) => getOrderObj(order))
    );

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(data);
    link.setAttribute("download", "order_history.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % orders.orders.length;
    setItemOffset(newOffset);
  };

  const handleDateSubmit = () => {};

  useEffect(() => {
    setFilteredOrders(
      orders.orders
        ?.filter((ord) =>
          startDate
            ? new Date(startDate).getTime() < new Date(ord.time).getTime()
            : true
        )
        ?.filter((ord) =>
          endDate
            ? new Date(endDate).getTime() > new Date(ord.time).getTime()
            : true
        )
    );
  }, [endDate, startDate]);

  return (
    <div className="container-fluid">
      {/* Content Row */}
      <div className="row">
        <div className={Page == 1 ? "col-12" : "col-xl-9 col-lg-8 mb-4"}>
          <div className="dashboardMain">
            {/* <div className="exchangeSupportPage orderHisPage"> */}
            <div className="exchangeSupportPage">
              <div
                className="head"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h2>Transaction History</h2>
                <button
                  style={{ height: "max-content" }}
                  className="themeBtn"
                  onClick={downloadCSV}
                >
                  Download
                </button>
              </div>
              <div className="connectOpBtn">
                <div className="connectOpBtn1">
                  <div className="cBtn m-0 active">Spot</div>
                  <div className="cBtn m-0 ">
                    Futures <small>(Coming Soon)</small>
                  </div>
                </div>
                {/* <div className="cBtn">Margin</div>
                  <div className="cBtn">Options</div> */}
                <div className="dBtn themeCardGlobal">
                  {/* <img className="inputBorder" src={selectBorder} alt="" /> */}

                  {/* <input className="form-control" type="date" /> */}
                  {/* <DatePicker
                        showIcon
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                      /> */}
                  <DatePicker
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                      setDateRange(update);
                    }}
                    placeholderText="Select from date"
                  />
                  <div className="submitDateMain">
                    <i
                      title="Submit"
                      class="fas fa-paper-plane"
                      onClick={() => handleDateSubmit()}
                    ></i>
                  </div>
                </div>
                {/* <div className='dBtn'>
                      <div className="submitDateMain">
                        <i title="Submit" class="fas fa-paper-plane" onClick={()=>handleDateSubmit()}></i>
                      </div>
                    </div> */}
              </div>
              <div className="">
                <div className="">
                  <div className="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">
                            <div className="">
                              <div>
                                <span>Time & Date</span>
                              </div>
                            </div>
                          </th>
                          <th scope="col">
                            <div className="">
                              <div>
                                <span>Pair</span>
                              </div>
                            </div>
                          </th>
                          <th scope="col">
                            <div className="">
                              <div>
                                <span>Type</span>
                              </div>
                            </div>
                          </th>
                          <th scope="col">
                            <div className="">
                              <div>
                                <span>Trader</span>
                              </div>
                            </div>
                          </th>
                          {authedUser?.authedUser?.master ? (
                            <>
                              <th scope="col">
                                <div className="">
                                  <div>
                                    <span>Followers Orders</span>
                                  </div>
                                </div>
                              </th>
                            </>
                          ) : (
                            <>
                              <th scope="col">
                                <div className="">
                                  <div>
                                    <span>Take profit</span>
                                  </div>
                                </div>
                              </th>
                              <th scope="col">
                                <div className="">
                                  <div>
                                    <span>Stop Loss</span>
                                  </div>
                                </div>
                              </th>
                            </>
                          )}

                          <th scope="col">
                            <div className="">
                              <div>
                                <span>Price</span>
                              </div>
                            </div>
                          </th>
                          <th scope="col">
                            <div className="">
                              <div>
                                <span>Order price executed</span>
                              </div>
                            </div>
                          </th>
                          <th scope="col">
                            <div className="">
                              <div>
                                <span>Qty </span>
                              </div>
                            </div>
                          </th>
                          <th scope="col">
                            <div className="">
                              <div>
                                <span>Qty. spent </span>
                              </div>
                            </div>
                          </th>
                          <th scope="col">
                            <div className="">
                              <div>
                                <span>Status</span>
                              </div>
                            </div>
                          </th>
                          <th scope="col">
                            <div className="">
                              <div>
                                <span>Profit</span>
                              </div>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentOrders.map((order) => {
                          return (
                            <tr>
                              <td scope="row">
                                <div>
                                  <div className="innerDiv">
                                    {order?.time ? (
                                      <div className="volume text-uppercase">
                                        {new Date(order?.time).getHours() > 9
                                          ? ""
                                          : "0"}
                                        {new Date(order?.time).getHours()}:
                                        {new Date(order?.time).getMinutes() > 9
                                          ? ""
                                          : "0"}
                                        {new Date(order?.time).getMinutes()} -{" "}
                                        {new Date(order?.time).getDate()}/
                                        {new Date(order?.time).getMonth() + 1}/
                                        {new Date(order?.time)
                                          .getFullYear()
                                          .toString()
                                          .substring(2)}
                                      </div>
                                    ) : (
                                      <div className="volume text-uppercase">
                                        --:--
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td scope="row">
                                <div>
                                  <div className="innerDiv">
                                    <div className="volume text-uppercase">
                                      {order?.symbol}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td scope="row">
                                <div>
                                  <div className="innerDiv">
                                    <div className="volume text-uppercase">
                                      {order?.side}{" "}
                                      <span style={{ textTransform: `none` }}>
                                        {order?.type === "LIMIT" ||
                                        order?.type === "MARKET"
                                          ? `(${order?.type})`
                                          : "(with TP/SL)"}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td scope="row">
                                <div>
                                  <div className="innerDiv">
                                    <div className="volume text-uppercase">
                                      {authedUser?.authedUser?.master
                                        ? "Self"
                                        : order?.cancelledBy ===
                                          authedUser?.authedUser?._id
                                        ? authedUser?.authedUser?.username
                                        : order?.master?.username
                                        ? order?.master?.username
                                        : "-"}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              {authedUser?.authedUser?.master ? (
                                <>
                                  <td scope="row">
                                    <div>
                                      <div className="innerDiv">
                                        <div className="volume text-uppercase">
                                          {order.fOrders ? order.fOrders : 0}
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td scope="row">
                                    <div>
                                      <div className="innerDiv">
                                        <div className="volume text-uppercase">
                                          {parseFloat(order?.stopPrice)
                                            ? parseFloat(order?.price).toFixed(
                                                2
                                              )
                                            : "-"}
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td scope="row">
                                    <div>
                                      <div className="innerDiv">
                                        <div className="volume text-uppercase">
                                          {parseFloat(order?.stopPrice)
                                            ? parseFloat(
                                                order?.stopPrice
                                              ).toFixed(2)
                                            : "-"}
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </>
                              )}
                              <td scope="row">
                                <div>
                                  <div className="innerDiv">
                                    {/* <div className="volume text-uppercase">{((order?.price ? parseFloat(order?.price).toFixed(4) : '-'))}</div> */}
                                    <div className="volume text-uppercase">
                                      {order?.type === "STOP_LOSS_LIMIT"
                                        ? parseFloat(order?.entryPrice).toFixed(
                                            4
                                          )
                                        : order?.status === "FILLED"
                                        ? parseFloat(
                                            parseFloat(
                                              order?.cummulativeQuoteQty
                                            ) / parseFloat(order?.origQty)
                                          ).toFixed(4)
                                        : parseFloat(order?.price).toFixed(4)}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td scope="row">
                                <div>
                                  <div className="innerDiv">
                                    <div className="volume text-uppercase">
                                      {parseFloat(order?.cummulativeQuoteQty) &&
                                      parseFloat(order?.origQty)
                                        ? parseFloat(
                                            order?.cummulativeQuoteQty /
                                              order?.origQty
                                          )
                                            .toString()
                                            .substring(0, 7)
                                        : "-"}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td scope="row">
                                <div>
                                  {/* {order?.side.toLowerCase() === 'buy' ? ( */}
                                  <div className="innerDiv">
                                    <div className="volume text-uppercase">
                                      {parseFloat(order?.origQty)
                                        ? `${order?.origQty
                                            .toString()
                                            .substring(0, 7)} ${
                                            order?.symbol.includes("-")
                                              ? order?.symbol.split("-")[0]
                                              : order?.symbol.substring(0, 3)
                                          }`
                                        : "-"}
                                    </div>
                                  </div>
                                  {/* // ) : (
                                    //   <div className="innerDiv">
                                    //     <div className="volume text-uppercase">{parseFloat(order?.cummulativeQuoteQty) ? order?.cummulativeQuoteQty.toString().substring(0,7) : (parseFloat((parseFloat(order?.origQty)*order?.price).toFixed(4)))}{` `}{order?.symbol.includes('-') ? order?.symbol.split('-')[1] :order?.symbol.substring(3)}</div>
                                    //   </div>
                                    // )} */}
                                </div>
                              </td>
                              <td scope="row">
                                <div>
                                  {/* {order?.side.toLowerCase() === 'buy' ? ( */}
                                  <div className="innerDiv">
                                    <div className="volume text-uppercase">
                                      {parseFloat(order?.cummulativeQuoteQty)
                                        ? `${order?.cummulativeQuoteQty
                                            .toString()
                                            .substring(0, 7)}`
                                        : parseFloat(
                                            (
                                              parseFloat(order?.origQty) *
                                              order?.price
                                            ).toFixed(4)
                                          )}
                                      {` `}
                                      {order?.symbol.includes("-")
                                        ? order?.symbol.split("-")[1]
                                        : order?.symbol.substring(3)}
                                    </div>
                                  </div>
                                  {/* ) : (
                                      <div className="innerDiv">
                                        <div className="volume text-uppercase">{parseFloat(order?.origQty) ? `${order?.origQty.toString().substring(0,7)} ${order?.symbol.includes('-') ? order?.symbol.split('-')[0] : order?.symbol.substring(0,3)}` : '-' }</div>
                                      </div>
                                    )} */}
                                </div>
                              </td>
                              <td scope="row">
                                <div>
                                  <div className="innerDiv">
                                    <div className="volume text-uppercase">
                                      {order?.status === "NEW" ? "Pending" : ""}
                                      {order?.status === "FILLED"
                                        ? "Executed"
                                        : ""}
                                      {order?.status === "CANCELLED"
                                        ? "Cancelled"
                                        : ""}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <div className="innerDiv">
                                    {/* <div className="volume text-uppercase">                           */}
                                    {getOrderProfit(order)}
                                    {/* </div> */}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <ReactPaginate
                      className="pagination"
                      breakLabel="..."
                      nextLabel=" >"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel="< "
                      renderOnZeroPageCount={null}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* diver status */}
        {/* <div className="col-xl-3 col-lg-4 mb-4 pr-0">
          <RightSidebar />
      </div> */}
      </div>
    </div>
  );
};
