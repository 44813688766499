import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../redux/ActionCreators";
import profile from "../../images/profile.jpg";
import Select from "react-select";
import success from "../../images/success.svg";
import bellIco from "../../images/bellIco.svg";
import ReactPaginate from "react-paginate";

import "./copytrade.css";
import close from "../../images/close.svg";
import { ConditionModal } from "../conditionModal";
import { toast } from "react-toastify";
import { PortfolioChart } from "../portfolio/porfolio";

function FolowTrade() {
  const { traderId } = useParams();
  const [show, setShow] = useState(false);
  const [traderDetails, setTraderDetails] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [showPostFollowModal, setPostFollowModal] = useState(false);
  const [showTCModal, setShowTCModal] = useState(false);
  const [totalOrders, setTotalOrders] = useState(0);
  const [symbols, setSymbols] = useState([]);
  const [totalVolume, setTotalVolume] = useState(0);

  const authedUser = useSelector((state) => state.authedUser);
  const balances = useSelector((state) => state.balances);
  const [followMode, setFollowMode] = useState(
    authedUser?.authedUser?.followMode ? authedUser?.authedUser?.followMode : 1
  );
  const [followModeAmount, setFollowAmount] = useState(
    authedUser?.authedUser?.followModeAmount
      ? authedUser?.authedUser?.followModeAmount
      : ""
  );

  const dispatch = useDispatch();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [stopLoss, setStopLoss] = React.useState(
    authedUser.authedUser.stopLoss ? authedUser.authedUser.stopLoss : ""
  );
  const [takeProfit, setTakeProfit] = React.useState(
    authedUser.authedUser.takeProfit ? authedUser.authedUser.takeProfit : ""
  );

  const saveRiskManagement = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/users/risk-management`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authedUser.authedUser.token}`,
      },
      body: JSON.stringify({
        stopLoss: stopLoss ? stopLoss : 0,
        takeProfit: takeProfit ? takeProfit : 0,
      }),
    })
      .then((r) => r.json())
      .then((res) => {
        if (res.success) {
          dispatch(updateUser(res.user));
          handleClose();
        } else {
          toast(res.msg ? res.msg : "Failed", {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: "dark",
          });
          handleClose();
        }
      });
  };

  const getCurrencies = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/currencies/symbols/binance`)
      .then((r) => r.json())
      .then((res) => {
        if (res.success) {
          setSymbols(res.symbols);
        }
      });
  };

  const changeBannedCurrency = (symbol) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/currencies/change`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authedUser.authedUser.token}`,
      },
      body: JSON.stringify({ symbol }),
    })
      .then((r) => r.json())
      .then((res) => {
        if (res.success) {
          dispatch(updateUser(res.user));
        }
      });
  };

  const fetchTrader = async () => {
    fetch(process.env.REACT_APP_BASE_URL + "/users/master/" + traderId, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((r) => r.json())
      .then((res) => {
        if (res.success) {
          if (res.user) {
            setTraderDetails(res.user);
            fetch(
              `${process.env.REACT_APP_BASE_URL}/users/get-total-volume/${res.user?._id}`
            )
              .then((r) => r.json())
              .then((r) => {
                if (r.success) {
                  setTotalVolume(r.volume.toFixed(4));
                }
              });
            fetchOrders(res.user?._id);
          }
        }
        setLoading(false);
      });
  };

  const fetchOrders = (uId) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/users/get-total-orders/${uId}`)
      .then((r) => r.json())
      .then((res) => {
        if (res.success) {
          setTotalOrders(res.totalOrders);
        }
      });
  };
  const followCheck = () => {
    if (!!authedUser.authedUser.following) {
      follow();
    } else {
      setShowTCModal(true);
    }
  };

  const follow = async () => {
    if (Object.keys(authedUser.authedUser)) {
      if (!authedUser.authedUser.master) {
        fetch(`${process.env.REACT_APP_BASE_URL}/users/follow`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ master: traderId }),
        })
          .then((r) => r.json())
          .then((res) => {
            if (res.success) {
              dispatch(updateUser(res.user));
              setTraderDetails(res.master);
              if (res.user?.following) {
                setPostFollowModal(true);
              }
            }
          });
      } else {
        toast("Master cannot follow others", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
        });
      }
    } else {
      toast("You are not logged in", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "dark",
      });
    }
  };

  const updateFollowMode = () => {
    let valid = true,
      error = "";
    if (followMode === 1) {
      if (followModeAmount <= 10 || !followModeAmount) {
        valid = false;
        error =
          "For fixed amount mode, the USD value should be greater than 10";
      }
    }
    if (followMode === 2) {
      if (followModeAmount < 0.1 || !followModeAmount) {
        valid = false;
        error =
          "For multiplier mode, the % value should be greater than or equal to 0.1";
      }
    }
    if (valid) {
      fetch(`${process.env.REACT_APP_BASE_URL}/users/update-follow-mode`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authedUser?.authedUser?.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ followMode, followModeAmount }),
      })
        .then((r) => r.json())
        .then((res) => {
          if (res.success) {
            dispatch(updateUser(res.user));
            toast("Success", {
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: "dark",
            });
          } else {
            toast(res.msg, {
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: "dark",
            });
          }
        });
    } else {
      toast(error, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "dark",
      });
    }
  };

  useEffect(() => {
    if (traderId) {
      fetchTrader();
      getCurrencies();
    }
  }, [traderId]);

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "rgb(26, 26, 26)" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: "rgb(26, 26, 26)",
        color: "#FFF",
        "&:hover": {
          backgroundColor: "#FFF",
          color: "black",
        },
      };
    },
    menuList: (styles) => ({ ...styles, backgroundColor: "rgb(26, 26, 26)" }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
    }),
  };

  if (isLoading) {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h4>Loading....</h4>
          </div>
        </div>
      </div>
    );
  } else if (Object.keys(traderDetails).length === 0) {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h3>404: No trader found</h3>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        {/* Begin Page Content */}
        <div className="container-fluid">
          {/* Content Row */}
          {/* <Balancebar /> */}
          <div className="row">
            <div className="col-xl-12 col-lg-12 mb-4">
              <div className="followTraderPage">
                <div className="header">
                  <h2>Follow The Trader</h2>
                </div>

                <div className="tradeDetailPage">
                  {authedUser?.authedUser?.following === traderDetails._id ? (
                    <>
                      <div className="riskRow row">
                        <div className="col-md-6">
                          <div className="profileCard themeCardGlobal">
                            {/* <img src={profileBannerBorder} alt="" /> */}
                            <div className="row1">
                              <div>
                                <div className="name">
                                  {traderDetails?.username}
                                </div>
                                <div className="followers">
                                  {traderDetails?.followers} Followers
                                </div>
                              </div>
                              <button
                                className="themeBtn"
                                onClick={followCheck}
                              >
                                <img
                                  className="mr-2"
                                  src={bellIco}
                                  alt="bellIco"
                                />
                                {authedUser?.authedUser?.following === traderId
                                  ? "Unfollow"
                                  : "Follow"}
                              </button>
                            </div>
                            <div className="statsRow mt-0 row2 mb-0">
                              <div className="assetRow">
                                <div className="fugure">
                                  <div className="val">****</div>
                                  <div className="title">Total asset</div>
                                </div>
                                <div className="fugure">
                                  <div className="val">{totalOrders}</div>
                                  <div className="title">Total Orders</div>
                                </div>
                                <div className="fugure">
                                  <div className="val">{totalVolume}$</div>
                                  <div className="title">Total Volume</div>
                                </div>
                                <div className="fugure">
                                  <div className="val">
                                    {traderDetails?.followers}
                                  </div>
                                  <div className="title">Followers</div>
                                </div>
                              </div>
                            </div>
                            <div className="dataRow row">
                              <div className="col">
                                <div className="col1">
                                  <div className="r1 ">
                                    <div
                                      className="title"
                                      style={{ textTransform: "none" }}
                                    >
                                      Which currency would you like to exclude?
                                    </div>
                                    {/* <div className="themeOutlineBtn">Edit</div> */}
                                  </div>
                                  <div className="pairMain row pt-0 mx-0">
                                    <div className="col-12 mb-3 px-0">
                                      <div className="selectM">
                                        {/* <div className="borderImg">
                                                                    <img src={selectBorder} alt="" />
                                                                </div> */}
                                        <Select
                                          isDisabled={
                                            authedUser.authedUser?.following !==
                                            traderDetails._id
                                          }
                                          value={null}
                                          styles={colourStyles}
                                          onChange={(e) =>
                                            changeBannedCurrency(e.value)
                                          }
                                          options={symbols
                                            .filter(
                                              (s) =>
                                                !authedUser.authedUser?.bannedCurrencies.includes(
                                                  s.symbol
                                                )
                                            )
                                            .map((s) => ({
                                              value: s.symbol,
                                              label: s.symbol,
                                            }))}
                                        />
                                      </div>
                                    </div>
                                    {authedUser.authedUser?.following ===
                                    traderDetails._id ? (
                                      <>
                                        {authedUser.authedUser
                                          .bannedCurrencies ? (
                                          authedUser.authedUser.bannedCurrencies.map(
                                            (a) => {
                                              return (
                                                <div
                                                  key={a}
                                                  className="col-md-2 px-0"
                                                >
                                                  <div className="pairBtn">
                                                    <span>{a}</span>{" "}
                                                    <span
                                                      style={{
                                                        fontWeight: 700,
                                                        fontSize: "16px",
                                                        cursor: "pointer",
                                                        marginLeft: 10,
                                                      }}
                                                      onClick={() =>
                                                        changeBannedCurrency(a)
                                                      }
                                                    >
                                                      x
                                                    </span>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="modeCard themeCardGlobal">
                            {/* <div className="borderCutLeft">
                                                <img src={traderBorder} alt="" />
                                            </div> */}
                            <div className="r1">
                              <div className="title">Follow Mode</div>
                              <button
                                onClick={updateFollowMode}
                                className="themeBtn"
                              >
                                Transfer
                              </button>
                            </div>
                            <div className="content">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div className="selectMode">
                                  <div
                                    onClick={() => {
                                      followMode === 0
                                        ? setFollowAmount(
                                            authedUser?.authedUser
                                              ?.followModeAmount
                                          )
                                        : setFollowAmount("");
                                      followMode === 1
                                        ? setFollowMode(0)
                                        : setFollowMode(1);
                                    }}
                                    style={{
                                      cursor:
                                        authedUser.authedUser?.following ===
                                        traderDetails._id
                                          ? "pointer"
                                          : "default",
                                    }}
                                    className={
                                      followMode === 1 ? "mode active" : "mode"
                                    }
                                  >
                                    Fixed amount
                                  </div>
                                  <div
                                    onClick={() => {
                                      followMode === 0
                                        ? setFollowAmount(
                                            authedUser?.authedUser
                                              ?.followModeAmount
                                          )
                                        : setFollowAmount("");
                                      followMode === 2
                                        ? setFollowMode(0)
                                        : setFollowMode(2);
                                    }}
                                    style={{
                                      cursor:
                                        authedUser.authedUser?.following ===
                                        traderDetails._id
                                          ? "pointer"
                                          : "default",
                                    }}
                                    className={
                                      followMode === 2 ? "mode active" : "mode"
                                    }
                                  >
                                    Multiplier
                                  </div>
                                </div>
                                <div
                                  className="themeBtn"
                                  onClick={() => {
                                    setFollowMode(0);
                                    setFollowAmount("");
                                  }}
                                >
                                  Set to Default
                                </div>
                              </div>
                              <div className="inputMai py-4">
                                <div className=" w-100">
                                  <input
                                    type="number"
                                    value={followModeAmount}
                                    onChange={(e) =>
                                      setFollowAmount(
                                        parseFloat(e.target.value)
                                      )
                                    }
                                    isDisabled={
                                      authedUser.authedUser?.following !==
                                        traderDetails._id || followMode === 0
                                    }
                                    placeholder={
                                      followMode === 2
                                        ? ">0.1 (10%)"
                                        : "(10 - 30000)"
                                    }
                                    className="form-control"
                                  />
                                </div>
                                <div className="currency">USD</div>
                              </div>
                              {/* <div className="btnRightCut w-100 my-2" onClick={updateFollowMode} >
                                                    <img className='' src={rightCutBtn} alt=""  />
                                                    <span>Transfer</span>
                                                </div> */}
                              <div className="tagline">
                                Invest -- USD of principal in each copy trading
                                order
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {balances.connectedExchanges[0]?.exchange === "bitget" ? (
                        <></>
                      ) : (
                        <div className="riskRow row">
                          <div className="col-md-6">
                            <div className="modeCard themeCardGlobal">
                              <div className="r1">
                                <div className="title">Risk Management</div>
                                {/* <button className="themeOutlineBtn">Edit</button> */}
                              </div>
                              <div className="content">
                                <div className="desc mb-3">
                                  Stop loss ratio:{" "}
                                  {authedUser.authedUser.stopLoss
                                    ? authedUser.authedUser.stopLoss
                                    : `--`}
                                  %
                                </div>
                                <div className="desc">
                                  Take profit ratio:{" "}
                                  {authedUser.authedUser.takeProfit
                                    ? authedUser.authedUser.takeProfit
                                    : `--`}
                                  %
                                </div>
                                <button
                                  className="themeBtn mt-4"
                                  onClick={handleShow}
                                >
                                  Edit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="prCard themeCardGlobal my-4">
                        <div className="statsRow tDetailBanner mt-0"></div>
                        <div className="statsRow row2 my-0">
                          <div className="pMain">
                            <div className="imgDiv">
                              <img
                                src={
                                  traderDetails?.profilepic
                                    ? `${process.env.REACT_APP_BASE_URL}/${traderDetails?.profilepic}`
                                    : profile
                                }
                                alt=""
                              />
                            </div>

                            <div>
                              <div className="name">
                                {traderDetails?.username}
                              </div>
                            </div>
                          </div>
                          <div className="assetRow">
                            <div className="fugure">
                              <div className="val">****</div>
                              <div className="title">Total asset</div>
                            </div>
                            <div className="fugure">
                              <div className="val">{totalOrders}</div>
                              <div className="title">Total Orders</div>
                            </div>
                            <div className="fugure">
                              <div className="val">{totalVolume}$</div>
                              <div className="title">Total Volume</div>
                            </div>
                            <div className="fugure">
                              <div className="val">
                                {traderDetails?.followers}
                              </div>
                              <div className="title">Followers</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-12 mb-3">
                          <PortfolioChart
                            masterId={traderId}
                            view={true}
                            vol={parseFloat(totalVolume)}
                          />
                        </div>
                      </div>

                      {/* table */}
                      <MasterTrades />
                    </>
                  )}
                  {/* table */}
                </div>
              </div>
            </div>

            {/* diver status */}
            {/* <div className="col-xl-3 col-lg-4 mb-4 pr-0">
                      <RightSidebar />
                  </div> */}
          </div>
        </div>

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Body className="editApiModal">
            {/* <img src={traderBorder} alt="" className="modalBorder" /> */}
            <div className="connectModal">
              <img
                className="closeIco"
                src={close}
                alt=""
                onClick={handleClose}
              />
              <h2>Risk Management</h2>
              <div className="riskMMain">
                <label className="mb-2">Stop loss ratio %</label>
                <br />
                {/* <RangeSlider rangeState={[stopLoss]} handleChange={handleChangeStopLoss} /> */}
                <div className="mb-3">
                  <input
                    type="number"
                    placeholder=">=1 (%)"
                    value={stopLoss}
                    onChange={(e) => setStopLoss(parseFloat(e.target.value))}
                  />
                  <br />
                </div>
                <label className="mb-2">Take profit ratio (optional)</label>
                <br />
                <div className="">
                  <input
                    type="number"
                    placeholder=">=1 (%)"
                    value={takeProfit}
                    onChange={(e) => setTakeProfit(parseFloat(e.target.value))}
                  />
                </div>
                {/* <RangeSlider rangeState={profitRatio} handleChange={handleChangeProfitRatio} /> */}
                {/* <label >Maximum follow</label>
                <RangeSlider rangeState={MaximumFollow} handleChange={handleChangeMaximumFollow} /> */}
              </div>

              <div className="continueBtn text-center">
                <button className="themeBtn mt-4" onClick={saveRiskManagement}>
                  Confirm
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <ConditionModal
          show={showTCModal}
          agreeFunc={() => {
            follow();
            setShowTCModal();
          }}
          handleClose={() => {
            setShowTCModal(false);
          }}
        />
        <Modal
          show={showPostFollowModal}
          onHide={() => setPostFollowModal(false)}
          centered
        >
          <Modal.Body>
            {/* <img src={modalBorder} alt="" className="modalBorder" /> */}

            <div className="connectModal OrderCompelteModal">
              <img
                className="closeIco"
                src={close}
                alt=""
                onClick={() => setPostFollowModal(false)}
              />
              <img className="OrderCompelteImg" src={success} alt="" />
              <h2>Followed Succesfully</h2>
              <div className="continueBtn text-center">
                <button
                  onClick={() => setPostFollowModal(false)}
                  className="themeBtn mt-4"
                >
                  Confirm
                </button>
                {/* <div className="btnRightCut w-100" onClick={() => setPostFollowModal(false)}>
                  <img className='' src={rightCutBtn} alt="" />
                  <span>Confirm</span>
                </div> */}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const MasterTrades = () => {
  const [orders, setOrders] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [offset, setOffset] = useState(0);
  const itemsPerPage = 10;

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % totalOrders;
    setOffset(newOffset);
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/users/master-orders/total-orders`)
      .then((r) => r.json())
      .then((res) => {
        if (res.success) {
          setTotalOrders(res.totalOrders);
        }
      });
  }, []);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/users/master-orders/offset/${offset}/pagesize/${itemsPerPage}`
    )
      .then((r) => r.json())
      .then((res) => {
        if (res.success) {
          setOrders(res.orders);
        }
      });
  }, [offset]);

  return (
    <div className="orderHisPage themeCardGlobal mt-4">
      <div className="theading mb-3">
        <h2 className="mb-0">Orders</h2>
      </div>

      <div className="table-responsive-xl px-3">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">
                <div className="">
                  <div className="">
                    <span>Date</span>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="">
                  <div className="">
                    <span>Time</span>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="">
                  <div className="">
                    <span>Pair</span>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="">
                  <div>
                    <span>Type</span>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="">
                  <div>
                    <span>Status</span>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="">
                  <div className="">
                    <span>Profit/Loss</span>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => {
              return (
                <tr key={order._id}>
                  <td scope="row">
                    <div>
                      <div className="innerDiv">
                        <div className="volume text-uppercase">
                          {order?.time ? (
                            <div className="volume text-uppercase">
                              {new Date(order?.time).getDate()}/
                              {new Date(order?.time).getMonth() + 1}/
                              {new Date(order?.time)
                                .getFullYear()
                                .toString()
                                .substring(2)}
                            </div>
                          ) : (
                            <div className="volume text-uppercase">
                              --/--/----
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td scope="row">
                    <div>
                      <div className="innerDiv">
                        {order?.time ? (
                          <div className="volume text-uppercase">
                            {new Date(order?.time).getHours() > 9 ? "" : "0"}
                            {new Date(order?.time).getHours()}:
                            {new Date(order?.time).getMinutes() > 9 ? "" : "0"}
                            {new Date(order?.time).getMinutes()}
                          </div>
                        ) : (
                          <div className="volume text-uppercase">--:--</div>
                        )}
                      </div>
                    </div>
                  </td>
                  <td scope="row">
                    <div>
                      <div className="innerDiv">
                        <div className="volume text-uppercase">
                          {order?.symbol}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td scope="row">
                    <div>
                      <div className="innerDiv">
                        <div className="volume text-uppercase">
                          {order?.side}{" "}
                          <span style={{ textTransform: `none` }}>
                            {order?.type === "LIMIT" || order?.type === "MARKET"
                              ? `(${order?.type})`
                              : "(with TP/SL)"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>

                  <td scope="row">
                    <div>
                      <div className="innerDiv">
                        <div className="volume text-uppercase">
                          {order?.status === "NEW" ? "Pending" : ""}
                          {order?.status === "FILLED" ? "Executed" : ""}
                          {order?.status === "CANCELLED" ? "Cancelled" : ""}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td scope="row">
                    <div>
                      <div className="innerDiv">
                        <div
                          className="volume text-uppercase"
                          style={
                            !!order?.profit
                              ? { color: order?.profit > 0 ? "green" : "red" }
                              : { color: "white" }
                          }
                        >
                          {order?.profit ? (
                            order.profit > 0 ? (
                              <>{`${order.profit}%`}</>
                            ) : (
                              <>{`${-1 * order.profit}%`}</>
                            )
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <ReactPaginate
          className="pagination"
          breakLabel="..."
          nextLabel=" >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={Math.ceil(totalOrders / itemsPerPage) - 1}
          previousLabel="< "
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  );
};

export { FolowTrade };
