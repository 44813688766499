import React, { lazy, Suspense } from "react";
import "./landingPage.css";
import { Navbar } from "./navbar";
import banner from "../../images/homeBanner.webp";
import aboutImage from "../../images/aboutImage.webp";
import wunderImage from "../../images/wunderImage.webp";
import bitget from "../../images/bitget.svg";
import okx from "../../images/okx.svg";
import binanceIco from "../../images/binanceIco.svg";
import easyIco from "../../images/easyIco.png";
import transparentIco from "../../images/transparentIco.png";
import safeIco from "../../images/safeIco.png";

import { Footer } from "./footer";
import { Link } from "react-router-dom";
import Typist from "react-typist";
const MasterStats = lazy(() => import("./masterStats"));

export const LandingPage = () => {
  return (
    <>
      <div className="landingPage">
        <Navbar />
        <section className="sec1">
          <div className="container">
            <div className="row ai-center">
              <div className="col-lg-6">
                <div className="content">
                  <Typist stdTypingDelay={300}>
                    <h1 id="typewriter">Tenup Copy Trading</h1>
                  </Typist>

                  <div className="desc">
                    {" "}
                    Embark on a journey into the world of cryptocurrencies with
                    the guidance of professional traders. Elevate your crypto
                    investment effortlessly through our TENUP copy trading
                    platform. Enjoy unparalleled transparency and maintain
                    complete control as your portfolio flourishes, all on
                    autopilot.
                  </div>
                  <Link to={"/login"} className="themeBtnHome">
                    Get Started
                  </Link>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="imgDiv">
                  <div className="effect"></div>
                  <img src={banner} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <div className="priceMarq" >
            <div id="coinmarketcap-widget-marquee" coins="1,1027,825,1839,4411,5426,2010" currency="USD" theme="dark" transparent="false" show-symbol-logo="true"></div>
        </div> */}

        <div className="sec3" id="exchanges">
          <div className="container">
            <h1 className="homeHeading">Connected exchanges</h1>
            <div className="row">
              {/* <div className="col-lg-3 col-6">
                        <div className="coinCard">
                            <img className='img-fluid' src={kuCoin} alt="" />
                        </div>
                    </div> */}
              <div className="col-lg-4 col-6">
                <div className="coinCard">
                  <img className="img-fluid" src={bitget} alt="" />
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="coinCard">
                  <img className="img-fluid okx" src={okx} alt="" />
                </div>
              </div>
              <div className="col-lg-4 col-6">
                <div className="coinCard">
                  <img className="img-fluid" src={binanceIco} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sec4" id="copytrade">
          <div className="container">
            <div className="row ai-center">
              <div className="col-lg-6">
                <div className="imgDiv">
                  <div className="effect"></div>
                  <img src={aboutImage} alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="content">
                  <h1 className="homeHeading mb-3">What is copy trading?</h1>
                  <div className="desc">
                    Copy-trading (aka mirror trading and shadow trading) is a
                    great tool for cryptocurrency investors who would like to
                    grow their cryptocurrency portfolio on autopilot. Copy
                    trading crypto is very dynamic. Our advanced copy trading
                    system will copy and execute the trades of the professional
                    traders that you follow automatically.
                  </div>
                  <Link to={"/login"} className="themeBtnHome">
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Suspense fallback={<></>}>
        <CoinGeckoHeatMap />
        </Suspense> */}
        <section className="sec5" id="feature">
          <div className="container">
            <div className="secTitle">Why Start</div>
            <h1 className="homeHeading">Copy Trading?</h1>
            <div className="secDesc">
              Unlock the potential of cryptocurrency investment with Tenup Copy
              Trading software. Experience effortless earnings, underpinned by
              simplicity, transparency, and security. Elevate your investment
              journey to new heights, all while enjoying peace of mind.
            </div>
            <div className="row">
              <div className="col-lg-4 mb-4">
                <div className="cCard">
                  <div className="imgDiv">
                    <img src={easyIco} alt="" />
                  </div>
                  <div className="title">Easy</div>
                  <p className="desc">
                    This is the easiest way to profit from crypto markets if you
                    do not have time to monitor the markets yourself. Follow
                    professional traders and enjoy the benefits of being in the
                    driver's seat of every trade.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 mb-4">
                <div className="cCard">
                  <div className="imgDiv">
                    <img src={transparentIco} alt="" />
                  </div>
                  <div className="title">Transparent</div>
                  <p className="desc">
                    All traders have fully transparent trade records. You can
                    analyse all the trades, statistics and metrics of every
                    trader that is available in the marketplace and select the
                    one that is right for your risk/reward appetite.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 mb-4">
                <div className="cCard">
                  <div className="imgDiv">
                    <img src={safeIco} alt="" />
                  </div>
                  <div className="title">Safe</div>
                  <p className="desc">
                    All the trading activity happens through API, which allows
                    the trader to execute the trades without the option of
                    withdrawing any funds.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Suspense fallback={<></>}>
          <MasterStats />
        </Suspense>
        <section className="sec5" id="feature">
          <div className="container">
            <h1 className="homeHeading">Subscription</h1>
            <h4 className="secTitle" style={{ fontWeight: "800" }}>
              Get Started with TenUp Copy Trading
            </h4>
            <div className="secDesc" style={{ maxWidth: "100%" }}>
              Unlock the power of expert trading strategies with TenUp Copy
              Trading. Our subscription model provides you with seamless access
              to follow top traders and automatically copy their trades across
              multiple exchanges. Take your trading journey to the next level
              with our non-custodial, cross-exchange copy trading platform..
            </div>
            <div className="row">
              <div className="col-lg-12 mb-4">
                <div className="cCard">
                  <div className="title mt-4">3000 TUP Tokens</div>
                  <p className="desc mb-4">3 Months of Access</p>
                  <div className="container my-4">
                    <div className="row">
                      <div
                        className="col-6"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "left",
                          }}
                        >
                          <p style={{ display: "flex", alignItems: "center" }}>
                            <div
                              style={{
                                backgroundColor: "rgba(103, 225, 174, 1)",
                                marginRight: "10px",
                                height: "10px",
                                width: "10px",
                                borderRadius: "50%",
                              }}
                            ></div>{" "}
                            Follow experienced master traders
                          </p>
                          <p style={{ display: "flex", alignItems: "center" }}>
                            <div
                              style={{
                                backgroundColor: "rgba(103, 225, 174, 1)",
                                marginRight: "10px",
                                height: "10px",
                                width: "10px",
                                borderRadius: "50%",
                              }}
                            ></div>{" "}
                            Real-time trade execution
                          </p>
                          <p style={{ display: "flex", alignItems: "center" }}>
                            <div
                              style={{
                                backgroundColor: "rgba(103, 225, 174, 1)",
                                marginRight: "10px",
                                height: "10px",
                                width: "10px",
                                borderRadius: "50%",
                              }}
                            ></div>{" "}
                            Secure, user-friendly, and flexible
                          </p>
                        </div>
                      </div>
                      <div
                        className="col-6"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "left",
                          }}
                        >
                          <p style={{ display: "flex", alignItems: "center" }}>
                            <div
                              style={{
                                backgroundColor: "rgba(103, 225, 174, 1)",
                                marginRight: "10px",
                                height: "10px",
                                width: "10px",
                                borderRadius: "50%",
                              }}
                            ></div>{" "}
                            Cross-exchange support (Binance, Bitget, OKX)
                          </p>
                          <p style={{ display: "flex", alignItems: "center" }}>
                            <div
                              style={{
                                backgroundColor: "rgba(103, 225, 174, 1)",
                                marginRight: "10px",
                                height: "10px",
                                width: "10px",
                                borderRadius: "50%",
                              }}
                            ></div>{" "}
                            Non-custodial platform (you retain control of your
                            assets)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sec5" id="feature">
          <div className="container">
            <div className="homeHeading my-3">How to Subscribe</div>
            <div className="row">
              <div className="col-lg-4 mb-4">
                <div className="how-to-subs-step mx-5">
                  <div className="how-to-subs-step-num">01</div>
                  <div className="how-to-subs-text-div">
                    <div className="how-to-subs-text">Connect Your Wallet:</div>
                    <div className="how-to-subs-sub-text">
                      Link your Metamask or compatible wallet.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-4">
                <div className="how-to-subs-step mx-5">
                  <div className="how-to-subs-step-num">02</div>
                  <div className="how-to-subs-text-div">
                    <div className="how-to-subs-text">Deposit TUP Tokens:</div>
                    <div className="how-to-subs-sub-text">
                      Ensure your wallet has a balance of 3000 TUP tokens.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-4">
                <div className="how-to-subs-step mx-5">
                  <div className="how-to-subs-step-num">03</div>
                  <div className="how-to-subs-text-div">
                    <div className="how-to-subs-text">Subscribe:</div>
                    <div className="how-to-subs-sub-text">
                      Confirm your subscription and start following top traders
                      immediately.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <h1
          className="homeHeading text-center mt-4"
          style={{ cursor: "pointer", marginBottom: "20px" }}
          onClick={() => {
            window.open(
              "https://www.youtube.com/watch?v=w0OOxMMj7oQ",
              "_black"
            );
          }}
        >
          Let's check it out.
        </h1>

        {/* <Suspense fallback={<></>}>
        <LandingPageYT1 />
        </Suspense> */}

        <section>
          <div className="container">
            <div className="row">
              <div
                className="col-12"
                style={{
                  backgroundColor: "rgba(103, 225, 174, 1)",
                  borderRadius: "10px",
                  fontSize: "32px",
                  fontWeight: "800",
                  color: "black",
                  padding: "20px 25px",
                  textAlign: "center",
                }}
              >
                Join the TenUp Copy Trading community today and let expert
                traders work for you!
              </div>
            </div>
          </div>
        </section>

        <div className="sec4">
          <div className="container">
            <div className="row ai-center">
              <div className="col-lg-6">
                <div className="imgDiv">
                  <div className="effect"></div>
                  <img src={wunderImage} alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="content">
                  <h1 className="homeHeading mb-3">
                    How to copy Trade on TENUP COPY TRADING?
                  </h1>
                  <div className="smHead">Connect Exchange</div>
                  <div className="desc mb-4">
                    In your exchange account, create an API and add it in the
                    copy trading app TENUP COPY TRADING.
                  </div>
                  <div className="smHead">Select Trader</div>
                  <div className="desc mb-4">
                    Open the Marketplace tab, analyse the available Traders and
                    choose the one you would like to follow.
                  </div>
                  <Link to={"/login"} className="themeBtnHome">
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="sec6" id="how-work">
          <div className="container">
            <div className="homeHeading">How does copy trading work?</div>
            <div className="desc">
              Once a Trader creates an order in his account at TENUP COPY
              TRADING, our copy trading platform will copy that trade and it
              will be replicated on your account automatically. In other words,
              Traders create signals that are then sent to copy-trading.You will
              see all strategies in the Positions tab of the console and you
              will also have full control over it. As a copy-trader you can
              close or edit the strategy, or wait for the exit signal from the
              Trader. Our crypto bot trading service runs 24/7.Apart from the
              main copy-trading function, Copy-Traders have a number of options
              that adds flexibility and more control over the process of
              copy-trading. For example, when a Copy-Trader decides to start
              copying a specific Trader, he can specify the custom size of funds
              used for copy-trading. It is possible to choose a share of the
              portfolio that will be used for copy-trading or a fixed amount.
              For example a Copy-Trader specifies $100 to be used as a fixed
              amount custom setting, then every trade opened by the Trader will
              use $100 of the Copy-Traders available funds.
            </div>
            <div className="videoMain">
              {/* <video poster={videoPoster} width={"400px"} controls playsInline>
                        <source src={videoPoster} type="video/mp4" />
                    </video> */}
            </div>
          </div>
        </section>

        {/* <section className="sec7" >
            <div className="container">
                <h1 className="homeHeading">More Copy Trading</h1>
                <div className='row' >
                    <div className="col-lg-4 col-md-6 mb-4">
                        <div className="tCard">
                            <div className="imgDiv">
                                <img src={binanceIco1} alt="" className="img-fluid" />
                            </div>
                            Binance Copy Trading
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-4">
                        <div className="tCard">
                            <div className="imgDiv">
                                <img src={bybitIco} alt="" className="img-fluid" />
                            </div>
                            Bybit Copy Trading
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-4">
                        <div className="tCard">
                            <div className="imgDiv">
                                <img src={okxIco} alt="" className="img-fluid" />
                            </div>
                            OKX Copy Trading
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-4">
                        <div className="tCard">
                            <div className="imgDiv">
                                <img src={bitmexIco} alt="" className="img-fluid" />
                            </div>
                            BitMEX Copy Trading
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-4">
                        <div className="tCard">
                            <div className="imgDiv">
                                <img src={kucoinIco} alt="" className="img-fluid" />
                            </div>
                            Bybit Copy Trading
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-4">
                        <div className="tCard">
                            <div className="imgDiv">
                                <img src={BtcIco} alt="" className="img-fluid" />
                            </div>
                            Bitcoin Copy Trading
                        </div>
                    </div>
                </div>
            </div>
        </section> */}

        {/* <section className='trialSec'>
            <div className="container">
                <h1 className="homeHeading">Start a 30 DAY FREE Trial. Try Now.</h1>
                <Link to={"/login"} className="themeBtnHome">Get Started</Link>
            </div>
        </section> */}

        <Footer />
      </div>
    </>
  );
};
