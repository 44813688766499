import React from "react";
import send from "../../images/sendOutlineIco.svg";
import logo from "../../images/logo.svg";
import { useLocation } from "react-router-dom";

export const Footer = () => {
  let location = useLocation();
  return (
    <>
      <footer>
        <div className="effect"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="logo">
                <img src={logo} alt="" className="img-fluid" />
              </div>
              <p className="copyWrite">Copyright © 2025 Tenup copy trading.</p>
              <p className="copyWrite">All rights reserved</p>
              <div className="socialMain">
                <a
                  href="https://www.instagram.com/tenupnation"
                  target="_blank"
                  className="item"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  href="https://twitter.com/TenupNation"
                  target="_blank"
                  className="item"
                >
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  href="https://www.youtube.com/channel/UC2FsNw9p7c8HDNdjRLknuiA?view_as=subscriber"
                  target="_blank"
                  className="item"
                >
                  <i className="fab fa-youtube"></i>
                </a>
                <a
                  href="https://www.facebook.com/TenUpNation"
                  target="_blank"
                  className="item"
                >
                  <i className="fab fa-facebook"></i>
                </a>
                <a
                  href="https://t.me/tenupnation"
                  target="_blank"
                  className="item"
                >
                  <i className="fab fa-telegram"></i>
                </a>
                <a
                  href="https://discord.gg/7cEuDJaHNc"
                  target="_blank"
                  className="item"
                >
                  <i className="fab fa-discord"></i>
                </a>
              </div>
            </div>
            <div className="col-md-2 col-6">
              <div className="head">Company</div>
              <ul>
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="https://tenup.io/" target="_blank">
                    About Us
                  </a>
                </li>
                <li>
                  <a href="https://blog.tenup.io/" target="_blank">
                    Blog
                  </a>
                </li>
                <li>
                  <a href="/terms" target="_blank">
                    Terms and Conditions
                  </a>
                </li>
                <li>
                  <a href="/privacy" target="_blank">
                    Privacy
                  </a>
                </li>
                <li>
                  <a
                    href={location.pathname === "/" ? "#feature" : "/#feature"}
                  >
                    Feature
                  </a>
                </li>
                <li>
                  <a
                    href={
                      location.pathname === "/" ? "#copytrade" : "/#copytrade"
                    }
                  >
                    Copy Trade
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-2 col-6">
              <div className="head">Support</div>
              <ul>
                <li>
                  <a href="https://dapp.tenup.io/wallet" target="_blank">
                    Tenup DAPP
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.travala.com/payment/tenup/flights"
                    target="_blank"
                  >
                    Flights Booking
                  </a>
                </li>
                <li>
                  <a
                    href="https://dapp.tenup.io/virtual-mining"
                    target="_blank"
                  >
                    Virtual Staking
                  </a>
                </li>
                <li>
                  <a href="https://analyzer.tenup.io/" target="_blank">
                    Tenup Analyzer
                  </a>
                </li>
                <li>
                  <a href="https://ludolove.tenup.io/" target="_blank">
                    Ludo Love
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <div className="head">Stay up to date</div>
              <div className="mail">
                <input type="text" placeholder="Your email address" />
                <img src={send} alt="" />
              </div>
              {/* <!-- TrustBox widget - Review Collector --> */}
              <div
                className="mt-3 trustpilot-widget"
                data-locale="en-NZ"
                data-template-id="56278e9abfbbba0bdcd568bc"
                data-businessunit-id="6005906f29f2c60001b706db"
                data-style-height="52px"
                data-style-width="100%"
              >
                <a
                  href="https://nz.trustpilot.com/review/tenup.io"
                  target="_blank"
                  rel="noopener"
                >
                  Trustpilot
                </a>
              </div>
              {/* <!-- End TrustBox widget --> */}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
