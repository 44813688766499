import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import connectImg from "../../images/webp/connectImg.webp";
import kuCoin from "../../images/kuCoin.svg";
import bitget from "../../images/bitget.svg";
import okx from "../../images/okx.svg";
import binance from "../../images/binanceIco.svg";
import binanceYellow from "../../images/binanceYellow.svg";
import close from "../../images/close.svg";
import completeKyc from "../../images/complete-kyc.svg";
import kycCompleted from "../../images/kyc-completed.svg";

import tick from "../../images/tick.svg";
import refresh from "../../images/refresh.svg";
import binanceLogo from "../../images/binanceLogo.png";
import { RightSidebar } from "../global/rightSidebar/rightSidebar";
import {
  setBalances,
  setConnectedExchange,
  setTotalUSDBalance,
  setLoaderAction,
  updateUser,
} from "../../redux/ActionCreators";
import { toast } from "react-toastify";
import { KycModal } from "./kycModal";
import { PaymentFormComp } from "./paymentForm";
import AdminPanel from "./adminPanel";
import { compressFile, disableIllegalCharacters, disablePaste } from "../../helpers";

function Dashboard(props) {
  const [show, setShow] = useState(false);
  const [showApiModal, setShowApiModal] = useState(false);
  const [Page, setPage] = useState(1);
  const [usrs, setUsers] = useState([]);
  const [selectedExchange, setSelectedExchange] = useState("");
  const [selectedTrading, setSelectedTrading] = useState("");
  const [kycForm, setKYCForm] = useState(false);
  const [kycFormAdmin, setKYCFormAdmin] = useState(false);
  const [kycFile, setKycFile] = useState("");
  const [kycSubmitLoading, setKycSubmitLoading] = useState(false);
  const [kycFaceFile, setKycFaceFile] = useState("");
  const [kycUserAdmin, setKycUserAdmin] = useState("");
  const [selectedCountry, setSelectedCountry] = useState({});

  const openKYCForm = () => setKYCForm(true);
  const closeKYCForm = () => setKYCForm(false);

  const openKYCFormAdmin = () => setKYCFormAdmin(true);
  const closeKYCFormAdmin = () => {
    setKycUserAdmin("");
    setKYCFormAdmin(false);
  };

  const history = useHistory();

  const authedUser = useSelector((state) => state.authedUser);
  const balances = useSelector((state) => state.balances);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const submitKYC = async () => {
    setKycSubmitLoading(true)
    if (kycFile) {
      let name = document.getElementById("kyc-name").value;
      let idType = document.getElementById("kyc-id-type").value;
      // let country = document.getElementById('kyc-country').value;
      if (!name) {
        toast("Name is required", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
        });
      }
      if (!idType) {
        toast("ID Type is required", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
        });
      } else if (!selectedCountry.value || !selectedCountry.label) {
        toast("Country is required", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
        });
      } else {
        let formdata = new FormData();
        formdata.append("name", name);
        formdata.append("country", JSON.stringify(selectedCountry));
        formdata.append("idType", idType);
        await compressFile(kycFile, formdata, "kycfile")
        await compressFile(kycFaceFile, formdata, "kycfacefile")
        // formdata.append("kycfile", kycFile);
        // formdata.append("kycfacefile", kycFaceFile);
        try{
          const res = await fetch(`${process.env.REACT_APP_BASE_URL}/users/kyc-filled`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: formdata,
          })
          .then((r) => {
            if(r.ok) {
              return r.json()
            } else{
              return r.text()
            }
          })
          if (res.success) {
            toast("Submitted", {
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: "dark",
            });
            dispatch(
              updateUser({
                ...authedUser.authedUser,
                kycName: name,
                kycCountry: JSON.stringify(selectedCountry),
                kycFile: res.filepath,
              })
            );
            closeKYCForm();
          } else {
            toast(
              res.msg
                ? res.msg
                : "Failed. Allowed formats: pdf, jpeg, jpg, png",
              {
                position: toast.POSITION.BOTTOM_RIGHT,
                theme: "dark",
              }
            );
          }
        }
        catch(e){
          alert(e?.message ? e.message : "Failed...")
        }
      }
    } else {
      toast("File is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "dark",
      });
    }
    setKycSubmitLoading(false)
  };

  const kycFileChange = (e) => {
    if (e.target.files.length > 0) {
      setKycFile(e.target.files[0]);
    }
  };
  const kycFaceFileChange = (e) => {
    if (e.target.files.length > 0) {
      setKycFaceFile(e.target.files[0]);
    }
  };

  const handleCloseAPI = () => {
    setShowApiModal(false);
    document.getElementById("update-api-key").value = "";
    document.getElementById("update-api-key-secret").value = "";
    document.getElementById("update-api-key-name").value = "";
  };
  const handleShowAPI = () => setShowApiModal(true);

  const getUsersToApprove = (jwt, alertt = false) => {
    fetch(process.env.REACT_APP_BASE_URL + "/users/all", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
      .then((r) => {
        if (r.ok) {
          return r.json();
        } else {
          return { success: false, msg: "Failed" };
        }
      })
      .then((res) => {
        if (res.success) {
          setUsers(res.users);
          if (alertt) {
            toast("Updated users", {
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: "dark",
            });
          }
        } else {
          toast(res.msg, {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: "dark",
          });
        }
      });
  };

  useEffect(() => {
    if (authedUser.authedUser?.admin) {
      getUsersToApprove(localStorage.getItem("token"));
    }
  }, [authedUser.authedUser]);

  const continueToExchange = () => {
    if (selectedExchange && selectedTrading) {
      let a = balances.connectedExchanges.some(
        (ex) => ex.exchange === selectedExchange
      );
      if (!a) {
        handleClose();
        setPage(2);
      } else {
        toast("Already connected to this exchange", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
        });
      }
    } else {
      toast("Please select trading & exchange", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "dark",
      });
    }
  };

  const dispatch = useDispatch();

  const validateApikey = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/users/api-key/validate`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((r) => (r.ok ? r.json() : { success: false }))
      .then((res) => {
        if (res.success) {
          toast(`Your api key is valid`, {
            theme: "dark",
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          toast(res.msg ? res.msg : "Failed to validate", {
            theme: "dark",
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      });
  };

  const continueToExchangeData = () => {
    try {
      dispatch(setLoaderAction(true));
      // setPage(3);
      let name = document.getElementById("exch-name").value;
      let key = document.getElementById("exch-key").value;
      let secret = document.getElementById("exch-secret").value;
      let passphrase = "";
      if (selectedExchange === "okex" || selectedExchange === "bitget") {
        passphrase = document.getElementById("exch-passphrase").value;
      }
      fetch(process.env.REACT_APP_BASE_URL + "/users/api-keys", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({
          key,
          secret,
          name,
          passphrase,
          exchange: selectedExchange,
        }),
      })
        .then((r) => r.json())
        .then((r) => {
          dispatch(setLoaderAction(false));
          if (r.success) {
            props.validateJWT(localStorage.getItem("token"));
            dispatch(setBalances(r.balances));
            dispatch(setTotalUSDBalance(r.total));
            dispatch(setLoaderAction(false));
            history.push("/portfolio");
          } else {
            toast(r?.msg ? r?.msg : "Invalid API Credentials", {
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: "dark",
            });
          }
        });
    } catch (error) {
      console.log(error);
      dispatch(setLoaderAction(false));
      toast("Invalid API Credentials", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "dark",
      });
    }
  };

  const deleteApiKey = (_id) => {
    dispatch(setLoaderAction(true));
    fetch(`${process.env.REACT_APP_BASE_URL}/users/api-keys`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ apiKeyId: _id }),
    })
      .then((r) => r.json())
      .then((r) => {
        dispatch(setLoaderAction(false));
        if (r.success) {
          dispatch(
            setConnectedExchange(
              balances.connectedExchanges.filter((c) => c._id !== _id)
            )
          );
        }
      });
  };

  const updateApiKey = (_id) => {
    let apiKey = document.getElementById("update-api-key").value;
    let apiKeySecret = document.getElementById("update-api-key-secret").value;
    let apiKeyName = document.getElementById("update-api-key-name").value;
    dispatch(setLoaderAction(true));
    fetch(`${process.env.REACT_APP_BASE_URL}/users/api-keys`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        apiKeyId: _id,
        apiKey,
        apiKeyName,
        apiKeySecret,
      }),
    })
      .then((r) => r.json())
      .then((r) => {
        if (r.success) {
          dispatch(
            setConnectedExchange(
              balances.connectedExchanges.map((c) => {
                if (c._id !== _id) {
                  return c;
                } else {
                  return r.connectedExchange;
                }
              })
            )
          );
          dispatch(setLoaderAction(false));
          toast("Success", {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: "dark",
          });
          handleCloseAPI();
        }
      });
  };
  const verifyUserKYC = (id, status) => {
    if (authedUser?.authedUser?.admin) {
      fetch(process.env.REACT_APP_BASE_URL + "/users/update-kyc-status", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ userId: id, approved: status }),
      })
        .then((r) => r.json())
        .then((res) => {
          if (res.success) {
            setUsers(
              usrs.map((u) => {
                if (u._id === id) {
                  return res.user;
                } else {
                  return u;
                }
              })
            );
            toast("Success", {
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: "dark",
            });
            closeKYCFormAdmin();
          }
        });
    }
  };
  const approveUser = (id, status) => {
    if (authedUser?.authedUser?.admin) {
      fetch(process.env.REACT_APP_BASE_URL + "/users/update-status", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ userId: id, approved: status }),
      })
        .then((r) => r.json())
        .then((res) => {
          if (res.success) {
            setUsers(
              usrs.map((u) => {
                if (u._id === id) {
                  return res.user;
                } else {
                  return u;
                }
              })
            );
          }
        });
    }
  };
  const copyIP = () => {
    navigator.clipboard.writeText("18.142.153.81");
    toast("Copied", {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: "dark",
    });
  };
  const setPaidStatus = (id, status) => {
    if (authedUser?.authedUser?.admin) {
      fetch(process.env.REACT_APP_BASE_URL + "/users/update-paid-status", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ userId: id, status }),
      })
        .then((r) => r.json())
        .then((res) => {
          if (res.success) {
            setUsers(
              usrs.map((u) => {
                if (u._id === id) {
                  return res.user;
                } else {
                  return u;
                }
              })
            );
          }
        });
    }
  };

  useEffect(() => {
    console.log("------", authedUser);
    if (Object.keys(authedUser.authedUser).length === 0) {
      history.push("/login");
    }
  }, []);

  return (
    <>
      {/* Begin Page Content */}
      <div className="container-fluid">
        {/* Content Row */}
        {/* <Balancebar /> */}
        <div className="row mx-0">
          <div
            className={
              Page == 4 || authedUser?.authedUser?.admin
                ? "col-12"
                : "col-xl-9 col-lg-8 mb-4"
            }
          >
            <div className="dashboardMain">
              {authedUser.authedUser.admin ? (
                <AdminPanel
                  usrs={usrs}
                  getUsers={getUsersToApprove}
                  setKycUserAdmin={setKycUserAdmin}
                  openKYCFormAdmin={openKYCFormAdmin}
                  approveUser={approveUser}
                  setPaidStatus={setPaidStatus}
                />
              ) : (
                <>
                  {Page == 1 && (
                    <>
                      {/* <div className="borderCutLeft">
                      <img src={connectBorder} alt="" />
                    </div> */}
                      {authedUser.authedUser.kycVerified ? (
                        authedUser.authedUser.approved ? (
                          <>
                            {!authedUser?.authedUser?.master &&
                            !authedUser?.authedUser?.paid ? (
                              <PaymentFormComp />
                            ) : (
                              <div className="connectExMain row">
                                <div className="col1 col-xl-7">
                                  {balances.connectedExchanges.length > 0 ? (
                                    <>
                                      <h1 className="mt-2">
                                        You are connected to your{" "}
                                        <span
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {
                                            balances.connectedExchanges[0]
                                              .exchange
                                          }
                                        </span>{" "}
                                        account
                                      </h1>
                                      <p>
                                        <span style={{ color: "red" }}>
                                          NOTE:
                                        </span>{" "}
                                        You need to have minimum{" "}
                                        <span style={{ color: "red" }}>
                                          500$
                                        </span>{" "}
                                        in your wallet in order to follow along
                                        with trades that our master trader takes
                                        and enjoy maximum profit.
                                      </p>
                                    </>
                                  ) : (
                                    <h1>
                                      Connect an exchange to start Trading
                                    </h1>
                                  )}
                                  <p>
                                    Connect to one of your favorite exchange and
                                    use your trading skills to get followers
                                    along with profit.
                                  </p>
                                  {/*  */}
                                  {balances.connectedExchanges.length == 0 && (
                                    <button
                                      className="themeBtnHome"
                                      onClick={() => handleShow()}
                                    >
                                      Connect a New Account
                                    </button>
                                  )}
                                </div>
                                <div className="col2 col-xl-5 px-lg-0">
                                  <div className="imgDiv">
                                    <img
                                      src={connectImg}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="connectExMain row">
                            <div className="col1 ">
                              <h1>
                                Dear{" "}
                                {authedUser.authedUser.master
                                  ? "Master Trader,"
                                  : "Trader,"}{" "}
                              </h1>
                              <p>
                                We would like to express our gratitude for your
                                interest in joining Tenup Copy Trading as a
                                Master Trader. We understand your eagerness to
                                begin trading on our platform, but please be
                                advised that the approval process may take up to
                                3 to 4 business days.
                              </p>
                              <p>
                                In the event that you are waiting for more than
                                a week to receive your approval, we encourage
                                you to contact our dedicated support team at{" "}
                                <a
                                  className="themeColor"
                                  href="mailto:support@copytrade.tenup.io"
                                >
                                  support@copytrade.tenup.io.
                                </a>{" "}
                                Our team is available 24/7 to provide you with
                                prompt assistance and address any queries or
                                concerns you may have.
                              </p>
                              <p>
                                Once again, we appreciate your interest in
                                joining Tenup Copy Trading as a Master Trader
                                and we are confident that you will find great
                                success among our community of traders.
                              </p>
                              <p>Best regards,</p>
                              <p>The Tenup Copy Trading Team.</p>
                            </div>
                          </div>
                        )
                      ) : (
                        <div className="connectExMain row">
                          <div className="col1 col-xl-7">
                            {authedUser.authedUser.kycName ? (
                              <>
                                <h1 className="mt-2">
                                  Thank you for submitting your KYC documents!{" "}
                                </h1>
                                <p>
                                  Our team will review your submission within 24
                                  hours. If all the details are accurate and
                                  meet the requirements, we will approve your
                                  KYC and notify you via email.
                                </p>
                                <p>
                                  If you have any questions in the meantime,
                                  please feel free to contact us at{" "}
                                  <b style={{ color: `var(--themeGreen)` }}>
                                    support@copytrade.tenup.io
                                  </b>
                                </p>
                                <p>
                                  Thank you for your patience and cooperation.
                                </p>
                              </>
                            ) : (
                              <>
                                <h1 className="mt-2">Dear User, </h1>
                                <p>
                                  Please complete your KYC verification to start
                                  using the TenUp Copy Trading platform. After
                                  submission our team will carefully review your
                                  documents. Upon approval, you will receive an
                                  email notification with further details.
                                </p>
                                <p>
                                  Should you have any inquiries, do not hesitate
                                  to contact us at{" "}
                                  <b style={{ color: `var(--themeGreen)` }}>
                                    support@copytrade.tenup.io
                                  </b>
                                </p>

                                <p>We look forward to assisting you!</p>

                                <p>Team TenUp</p>
                              </>
                            )}
                            {authedUser?.authedUser?.kycName ? (
                              <></>
                            ) : (
                              <button
                                className="themeBtnHome"
                                onClick={openKYCForm}
                              >
                                KYC FORM
                              </button>
                            )}
                          </div>

                          <div className="col2 col-xl-5 px-lg-0">
                            <div className="imgDiv">
                              <img
                                src={
                                  authedUser.authedUser.kycName
                                    ? kycCompleted
                                    : completeKyc
                                }
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {balances.connectedExchanges.length > 0 ? (
                        <div className="row mt-4">
                          <h4 className="mx-2 col-12">Connected Exchanges</h4>

                          <div className="apiUpdateMAin">
                            {/* <div className="borderCutLeft">
                          <img src={borderCutRightPink} alt="" />
                        </div> */}
                            <div className="row1">
                              <div className="profileM">
                                <div className="logoDiv">
                                  {balances.connectedExchanges[0].exchange ===
                                    "binance" && (
                                    <img src={binanceLogo} alt="" />
                                  )}
                                  {balances.connectedExchanges[0].exchange ===
                                    "okex" && (
                                    <img
                                      src={okx}
                                      alt=""
                                      style={{ height: "40px" }}
                                    />
                                  )}
                                  {balances.connectedExchanges[0].exchange ===
                                    "bitget" && (
                                    <img
                                      src={bitget}
                                      alt=""
                                      style={{ height: "40px" }}
                                    />
                                  )}
                                </div>
                                <div className="name">
                                  My{" "}
                                  <span style={{ textTransform: "capitalize" }}>
                                    {balances.connectedExchanges[0].exchange}
                                  </span>
                                </div>
                              </div>
                              <div className="refresh" onClick={validateApikey}>
                                <img src={refresh} alt="" />
                              </div>
                            </div>
                            <div className="py-3">
                              <div className="hrLine"></div>
                            </div>
                            <div className="row2">
                              {/* <img src={warning} alt="" className="warning" /> */}
                              {/* <div className="text">Api Key is valid</div> */}
                              {/* <div className="msg">Please update API key</div> */}
                            </div>
                            <div className="btnMain">
                              {/* <div className="btnRightCut w-100" onClick={handleShowAPI}>
                            <img className='' src={rightCutBtn} alt=""  />
                            <span>Update API KEY</span>
                          </div>
                          <div className="btnRightCut w-100" onClick={() => deleteApiKey(balances.connectedExchanges[0]._id)}>
                            <img className='' src={rightCutBtn} alt=""  />
                            <span>Delete</span>
                          </div> */}
                              <button
                                className="themeBtnHome"
                                onClick={handleShowAPI}
                              >
                                Update Api Key
                              </button>
                              <button
                                onClick={() =>
                                  deleteApiKey(
                                    balances.connectedExchanges[0]._id
                                  )
                                }
                                className="themeBtnHome ml-3"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                  {Page === 2 ? (
                    <div className="connectExPage">
                      <div className="head">
                        <h2>
                          How to Generate and Add
                          {selectedExchange === "okex" ? " Okex " : ""}
                          {selectedExchange === "binance" ? " Binance " : ""}
                          {selectedExchange === "bitget" ? " Bitget " : ""}
                          API Keys?
                        </h2>
                      </div>

                      <div
                        className="mt-3 connectExMain row"
                        style={{
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <iframe
                            width="677"
                            height="380"
                            src={
                              selectedExchange === "okex"
                                ? "https://www.youtube.com/embed/DDZQN_otHmE?si=JDnfiFC8hwjc0dB8"
                                : selectedExchange === "bitget"
                                ? "https://www.youtube.com/embed/z3lmuuDFjXI?si=sOpmkaRuBBgB_lsv"
                                : selectedExchange === "binance"
                                ? "https://www.youtube.com/embed/FhiDnW1UrBo?si=CXspun-_kgUmgNkt"
                                : ""
                            }
                            title="Connect OKEX account"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin"
                            allowfullscreen
                          ></iframe>
                        </div>
                        <div className="text-center mt-4">
                          <button
                            className="themeBtnHome"
                            onClick={() => setPage(3)}
                          >
                            Continue
                          </button>

                          <div
                            style={{
                              color: "#67E1AE",
                              border: "1px solid",
                              borderRadius: "25px",
                              padding: "10px 21px",
                              cursor: "pointer",
                              marginLeft: "10px",
                              display: "inline-block",
                            }}
                            onClick={copyIP}
                          >
                            COPY OUR IP
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {Page === 3 ? (
                    <div className="connectExPage">
                      <div className="head jc-sb ai-center">
                        <h2>Connect exchange</h2>
                        <div className="backIco" onClick={() => setPage(2)}>
                          <i
                            className="fas fa-arrow-circle-left theme-color cursor-pointer"
                            style={{ fontSize: 20 }}
                          ></i>
                        </div>
                      </div>
                      <div className="descMain my-4">
                        {selectedExchange === "binance" ? (
                          <img
                            src={binanceYellow}
                            height={"50px"}
                            width={"100px"}
                            alt=""
                          />
                        ) : (
                          <></>
                        )}
                        {selectedExchange === "okex" ? (
                          <img
                            src={okx}
                            height={"50px"}
                            width={"100px"}
                            alt=""
                          />
                        ) : (
                          <></>
                        )}
                        {selectedExchange === "bitget" ? (
                          <img
                            src={bitget}
                            height={"50px"}
                            width={"100px"}
                            alt=""
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="form mt-3">
                        <div className="form-group">
                          <label>Name:</label>
                          <div className="">
                            <input
                              {...disablePaste}
                              {...disableIllegalCharacters}
                              type="text"
                              id="exch-name"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label>API Key:</label>
                          <div className="">
                            <input
                              {...disableIllegalCharacters}
                              type="text"
                              id="exch-key"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label>API Secret:</label>
                          <div className="">
                            <input
                              {...disableIllegalCharacters}
                              type="text"
                              id="exch-secret"
                              className="form-control"
                            />
                          </div>
                        </div>
                        {selectedExchange === "okex" ||
                        selectedExchange === "bitget" ? (
                          <div className="form-group">
                            <label>Passphrase:</label>
                            <input
                              {...disableIllegalCharacters}
                              type="text"
                              id="exch-passphrase"
                              className="form-control"
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                        <div className="text-center mt-4">
                          <button
                            className="themeBtnHome"
                            onClick={continueToExchangeData}
                          >
                            Connect
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {Page === 4 ? (
                    <div className="exchangeSupportPage">
                      <div className="head">
                        <h2>Supported Exchanges</h2>
                      </div>
                      <div className="connectOpBtn1">
                        <div className="bBtn active">Spot</div>
                        <div className="bBtn ">Futures</div>
                        <div className="bBtn ">Margin</div>
                        <div className="bBtn ">Options</div>
                      </div>
                      <div className="table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">
                                <div className="">
                                  <div>
                                    <span>Exchange</span>
                                  </div>
                                </div>
                              </th>
                              <th scope="col">
                                <div className="">
                                  <div>
                                    <span>Order Types</span>
                                  </div>
                                </div>
                              </th>
                              <th scope="col">
                                <div className="">
                                  <div>
                                    <span>Smart Trade</span>
                                  </div>
                                </div>
                              </th>
                              <th scope="col">
                                <div className="">
                                  <div>
                                    <span>DCA Bot</span>
                                  </div>
                                </div>
                              </th>
                              <th scope="col">
                                <div className="">
                                  <div>
                                    <span>Grid Bot</span>
                                  </div>
                                </div>
                              </th>
                              <th scope="col">
                                <div className="">
                                  <div>
                                    <span>Options Bot</span>
                                  </div>
                                </div>
                              </th>
                              <th scope="col">
                                <div className="">
                                  <div>
                                    <span>Volume (24h)</span>
                                  </div>
                                </div>
                              </th>
                              <th scope="col">
                                <div className="">
                                  <div>
                                    <span>Trading Pairs</span>
                                  </div>
                                </div>
                              </th>
                              <th scope="col">
                                <div className="">
                                  <div>
                                    <span>Connect</span>
                                  </div>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td scope="row">
                                <div>
                                  <div className="innerDiv">
                                    <div className="exIco">
                                      <img src={kuCoin} alt="" />
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <div className="innerDiv">
                                    <div className="orderTypeMain">
                                      <div className="orderBtn">Spot</div>
                                      <div className="orderBtn">Futures</div>
                                      <div className="orderBtn">Margin</div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <div className="innerDiv">
                                    <img src={tick} alt="" className="tick" />
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <div className="innerDiv">
                                    <img src={tick} alt="" className="tick" />
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <div className="innerDiv">
                                    <img src={tick} alt="" className="tick" />
                                  </div>
                                </div>
                              </td>

                              <td>
                                <div>
                                  <div className="innerDiv"></div>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <div className="innerDiv">
                                    <div className="volume">$62.35B</div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <div className="innerDiv">
                                    <div className="volume">1782</div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <div className="innerDiv">
                                    <div className="connectBtn">Connect</div>
                                    {/* <div className="btnRightCut w-100">
                                        <img className='' src={rightCutBtn} alt="" />
                                        <span>Connect</span>
                                      </div> */}
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td scope="row">
                                <div>
                                  <div className="innerDiv">
                                    <div className="exIco">
                                      <img src={kuCoin} alt="" />
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <div className="innerDiv">
                                    <div className="orderTypeMain">
                                      <div className="orderBtn">Spot</div>
                                      <div className="orderBtn">Futures</div>
                                      <div className="orderBtn">Margin</div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <div className="innerDiv">
                                    <img src={tick} alt="" className="tick" />
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <div className="innerDiv">
                                    <img src={tick} alt="" className="tick" />
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <div className="innerDiv">
                                    <img src={tick} alt="" className="tick" />
                                  </div>
                                </div>
                              </td>

                              <td>
                                <div>
                                  <div className="innerDiv"></div>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <div className="innerDiv">
                                    <div className="volume">$62.35B</div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <div className="innerDiv">
                                    <div className="volume">1782</div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <div className="innerDiv">
                                    <div className="connectBtn">Connect</div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td scope="row">
                                <div>
                                  <div className="innerDiv">
                                    <div className="exIco">
                                      <img src={kuCoin} alt="" />
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <div className="innerDiv">
                                    <div className="orderTypeMain">
                                      <div className="orderBtn">Spot</div>
                                      <div className="orderBtn">Futures</div>
                                      <div className="orderBtn">Margin</div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <div className="innerDiv">
                                    <img src={tick} alt="" className="tick" />
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <div className="innerDiv">
                                    <img src={tick} alt="" className="tick" />
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <div className="innerDiv">
                                    <img src={tick} alt="" className="tick" />
                                  </div>
                                </div>
                              </td>

                              <td>
                                <div>
                                  <div className="innerDiv"></div>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <div className="innerDiv">
                                    <div className="volume">$62.35B</div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <div className="innerDiv">
                                    <div className="volume">1782</div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <div className="innerDiv">
                                    <div className="connectBtn">Connect</div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          </div>

          {/* diver status */}
          {Page != 4 && (
            <div className="col-xl-3 col-lg-4 mb-4 pr-0 pl-xs-0">
              <RightSidebar />
            </div>
          )}
        </div>
      </div>
      {/* /.container-fluid */}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          {/* <img src={modalBorder} alt="" className="modalBorder" /> */}

          <div className="connectModal exchModal pt-0">
            <img
              className="closeIco"
              src={close}
              alt=""
              onClick={handleClose}
            />
            <h2>Connect an exchange</h2>
            <div className="pt-2 pb-4">
              <div className="hrLine"></div>
            </div>
            <div className="desc">
              Tenup will not have access to transfer or withdraw your assets.
              Each exchange connects with encrypted API keys
            </div>
            <div className="connectContent">
              <div className="optionsMain">
                <div
                  className={
                    selectedTrading === "spot" ? "opBtn active" : "opBtn"
                  }
                  onClick={() =>
                    setSelectedTrading(selectedTrading === "spot" ? "" : "spot")
                  }
                >
                  Spot
                </div>
                <div className="opBtn">
                  Futures <small>(Coming Soon)</small>
                </div>
              </div>
              <div className="exchangeMain">
                {/* <div className="opBtn"><img src={kuCoin} alt="" style={{opacity: 0.1}} /></div> */}
                <div
                  className="opBtn"
                  onClick={() =>
                    setSelectedExchange(
                      selectedExchange === "bitget" ? "" : "bitget"
                    )
                  }
                  style={{
                    background:
                      selectedExchange === "bitget"
                        ? "rgba(242, 242, 242, 0.3)"
                        : "",
                  }}
                >
                  <img src={bitget} alt="" />
                </div>
                <div
                  className="opBtn"
                  onClick={() =>
                    setSelectedExchange(
                      selectedExchange === "okex" ? "" : "okex"
                    )
                  }
                  style={{
                    background:
                      selectedExchange === "okex"
                        ? "rgba(242, 242, 242, 0.3)"
                        : "",
                  }}
                >
                  <img src={okx} alt="" />
                </div>
                <div
                  className="opBtn"
                  onClick={() =>
                    setSelectedExchange(
                      selectedExchange === "binance" ? "" : "binance"
                    )
                  }
                  style={{
                    background:
                      selectedExchange === "binance"
                        ? "rgba(242, 242, 242, 0.3)"
                        : "",
                  }}
                >
                  <img src={binance} alt="" />
                </div>
              </div>
              <div className="continueBtn text-center">
                <button
                  className="themeBtnHome"
                  onClick={() => continueToExchange()}
                >
                  Continue
                </button>
              </div>
            </div>
            {/* </div> */}
            {/* <div className="btnRightCut " onClick={()=>continueToExchange()}>
              <img className='' src={rightCutBtn} alt=""/>
              <span>Continue</span>
            </div> */}
          </div>
        </Modal.Body>
      </Modal>
      <KycModal
        show={kycForm}
        closeModal={closeKYCForm}
        name={authedUser.authedUser.kycName}
        country={authedUser.authedUser.kycCountry}
        idType={authedUser.authedUser.kycIdType}
        file={authedUser.authedUser.kycFile}
        faceFile={authedUser.authedUser.kycFaceFile}
        selectedCountry={selectedCountry}
        setSelectedCountry={setSelectedCountry}
        kycFileChange={kycFileChange}
        kycFaceFileChange={kycFaceFileChange}
        submitKYC={submitKYC}
        kycSubmitLoading={kycSubmitLoading}
      />
      {/* KYC MODAL FOR ADMIN VIEW */}
      <KycModal
        show={kycFormAdmin}
        closeModal={closeKYCFormAdmin}
        name={kycUserAdmin.kycName}
        country={kycUserAdmin.kycCountry}
        idType={kycUserAdmin.kycIdType}
        file={kycUserAdmin.kycFile}
        faceFile={kycUserAdmin.kycFaceFile}
        selectedCountry={selectedCountry}
        setSelectedCountry={setSelectedCountry}
        isAdmin={true}
        submitKYC={verifyUserKYC}
        uid={kycUserAdmin._id}
      />
      {/* Update API Modal */}
      <Modal className="" show={showApiModal} onHide={handleCloseAPI} centered>
        <Modal.Body>
          {/* <img src={modalBorder} alt="" className="modalBorder" /> */}

          <div className="otpModalMain editApiModal">
            <div className="closeIcon" onClick={handleCloseAPI}>
              <img src={close} alt="" />
            </div>
            <h2 className="title">Edit API</h2>
            <div className="pb-3">
              <div className="hrLine"></div>
            </div>
            <div className="editApiM">
              <div className="my-3 ">
                <label>Name</label>
                <br />
                <div className="">
                  <input
                    defaultValue={""}
                    id="update-api-key-name"
                    type="text"
                  />
                </div>
              </div>
              <div className="my-3">
                <label>Secret Key</label>
                <br />
                <div className="">
                  <input
                    defaultValue={""}
                    id="update-api-key-secret"
                    type="text"
                  />
                </div>
              </div>
              <div className="my-3">
                <label>API Key</label>
                <br />
                <div className="">
                  <input defaultValue={""} id="update-api-key" type="text" />
                </div>
              </div>
            </div>
            <div className="btnMain mt-4">
              <button
                type="submit"
                className="themeBtnHome "
                onClick={() => updateApiKey(balances.connectedExchanges[0]._id)}
              >
                Save
              </button>
              <button
                type="submit"
                className="themeBtnHomeOutline ml-4"
                onClick={handleCloseAPI}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export { Dashboard };
