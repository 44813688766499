import React, { useEffect, useState } from "react";
import { RightSidebar } from "../global/rightSidebar/rightSidebar";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateUser } from "../../redux/ActionCreators";
import Modal from "react-bootstrap/Modal";
import "./profile.css";
import closeIco from "../../images/close.svg";
import profile from "../../images/profile.jpg";
import { Balancebar } from "../global/balanceBar/balanceBar";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import AssetsTable from "../assets/assetsTable";

export default function Profile() {
  const authedUser = useSelector((state) => state.authedUser);
  const history = useHistory();
  const dispatch = useDispatch();
  const [editedImage, setEditedImage] = useState("");
  const [show, setShow] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const orders = useSelector((state) => state.orders);

  useEffect(() => {
    if (Object.keys(authedUser.authedUser) === 0) {
      history.push("/");
    }
  }, [authedUser.authedUser]);

  const editProfile = () => {
    let username = document.getElementById("edited-username").value;
    if (username) {
      let formdata = new FormData();
      formdata.append("username", username);
      if (editedImage) {
        formdata.append("img", editedImage, editedImage.name);
      }
      fetch(`${process.env.REACT_APP_BASE_URL}/users/profile`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: formdata,
      })
        .then((r) => {
          if (r.ok) {
            return r.json();
          } else {
            return r.text();
          }
        })
        .then((res) => {
          if (res.success) {
            setShow(false);
            return dispatch(updateUser(res.user));
          } else {
            setShow(false);
            toast(res.msg ? res.msg : "Failed.", {
              position: toast.POSITION.BOTTOM_RIGHT,
              theme: "dark",
            });
          }
        });
    } else {
      toast("Username cannot be empty", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "dark",
      });
    }
  };

  const fileChangeHandler = (e) => {
    console.log(e.target.files);
    if (e.target.files.length > 0) {
      let im = e.target.files[0];
      if (
        im.name.endsWith(".png") ||
        im.name.endsWith(".jpeg") ||
        im.name.endsWith(".jpg")
      ) {
        if (
          im.type === "image/png" ||
          im.type === "image/jpeg" ||
          im.type === "image/jpg"
        ) {
          setEditedImage(e.target.files[0]);
        } else {
          toast("Failed. Allowed formats: png, jpg, jpeg", {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: "dark",
          });
        }
      } else {
        toast("Failed. Allowed formats: png, jpg, jpeg", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
        });
      }
    }
  };

  return (
    <div className="container-fluid">
      {/* Content Row */}
      {/* <Balancebar /> */}
      <div className="row">
        <div className="col-12 mb-4">
          <div className="tradeDetailPage  profilePage followTraderPage">
            {/* <div className="header mt-4">
                <h2 className="mb-0">Overview</h2>
              </div> */}
            <div className="themeCardGlobal overflow-hidden">
              <div className="statsRow profileBanner m-0"></div>
              <div className="statsRow m-0">
                <div className="pMain">
                  <div className="imgDiv mr-0">
                    <img
                      className="img-fluid"
                      src={
                        authedUser.authedUser?.profilepic
                          ? `${process.env.REACT_APP_BASE_URL}/${authedUser.authedUser?.profilepic}`
                          : profile
                      }
                      alt=""
                    />
                    <div className="editPrMain">
                      <span className="icon" onClick={handleShow}>
                        <i class="far fa-edit"></i>
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="name">
                      {authedUser.authedUser?.username.length > 15
                        ? `${authedUser.authedUser?.username?.substring(
                            0,
                            14
                          )}..`
                        : authedUser.authedUser?.username}
                    </div>
                  </div>
                </div>
                <div className="assetRow">
                  <div className="fugure">
                    <h5 className="val">
                      {authedUser.authedUser?.master
                        ? authedUser.authedUser.followers
                        : authedUser.authedUser?.following
                        ? 1
                        : 0}
                    </h5>
                    <p className="title">
                      {authedUser.authedUser?.master
                        ? "Followers"
                        : "Following"}
                    </p>
                  </div>
                  <div className="fugure">
                    <h5 className="val">{orders.orders.length}</h5>
                    <p className="title">Total trades</p>
                  </div>
                  <div className="fugure">
                    <h5 className="val">
                      {orders.orders
                        .reduce(
                          (partialSum, order) =>
                            partialSum +
                            (order?.usdPrice ? parseFloat(order?.usdPrice) : 0),
                          0
                        )
                        .toFixed(2)}
                    </h5>
                    <p className="title">Volume</p>
                  </div>
                </div>
              </div>
            </div>

            {/* options */}
            <div>
              <ProfileDetailSection />
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div className="otpModalMain">
            <div className="closeIcon" onClick={handleClose}>
              <img src={closeIco} alt="" />
            </div>
            <h2 className="title">Edit Profile</h2>

            <div className="col1">
              <div className="my-3">
                <label>Username</label>
                <br />
                <input
                  style={{
                    width: "100%",
                    background: "none",
                    border: "0px",
                    borderBottom: "1px solid white",
                    color: "white",
                  }}
                  defaultValue={authedUser.authedUser.username}
                  id="edited-username"
                  type="text"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label>Profile Picture</label>
                <br />
                <img
                  onClick={() =>
                    document.getElementById("edit-profpic").click()
                  }
                  style={{
                    margin: "auto",
                    cursor: "pointer",
                    height: "100px",
                    width: "100px",
                    borderRadius: "50%",
                  }}
                  src={`${
                    editedImage
                      ? URL.createObjectURL(editedImage)
                      : authedUser.authedUser?.profilepic
                      ? `${process.env.REACT_APP_BASE_URL}/${authedUser.authedUser?.profilepic}`
                      : profile
                  }`}
                />
                <input
                  style={{ display: "none" }}
                  type="file"
                  id="edit-profpic"
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={fileChangeHandler}
                />
              </div>
              <div>
                <button onClick={editProfile} className="themeBtn mt-5">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
const itemsPerPage = 10;

const ProfileDetailSection = () => {
  const authedUser = useSelector((s) => s.authedUser);
  const orders = useSelector((s) => s.orders);
  const [itemOffset, setItemOffset] = useState(0);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const endOffset = itemOffset + itemsPerPage;
  let [activeTab, setActiveTab] = useState("assets");
  const currentOrders = orders.orders
    ?.filter((ord) =>
      startDate
        ? new Date(startDate).getTime() < new Date(ord.time).getTime()
        : true
    )
    ?.filter((ord) =>
      endDate
        ? new Date(endDate).getTime() > new Date(ord.time).getTime()
        : true
    )
    .slice(itemOffset, endOffset);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % orders.orders.length;
    setItemOffset(newOffset);
  };
  const pageCount = Math.ceil(orders.orders.length / itemsPerPage);

  return (
    <>
      <div className="chartOption">
        <div
          style={{ cursor: "pointer" }}
          onClick={() => setActiveTab("assets")}
          className={`cBtn ${activeTab === "assets" ? "active" : ""}`}
        >
          Assets
        </div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => setActiveTab("history")}
          className={`cBtn ${activeTab === "history" ? "active" : ""}`}
        >
          History
        </div>
      </div>
      {activeTab === "assets" ? (
        <div className="portfolioPage p-0">
          <AssetsTable />
        </div>
      ) : (
        <></>
      )}
      {activeTab === "history" ? (
        <div className="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">
                  <div className="">
                    <div>
                      <span>Time & Date</span>
                    </div>
                  </div>
                </th>
                <th scope="col">
                  <div className="">
                    <div>
                      <span>Pair</span>
                    </div>
                  </div>
                </th>
                <th scope="col">
                  <div className="">
                    <div>
                      <span>Type</span>
                    </div>
                  </div>
                </th>
                <th scope="col">
                  <div className="">
                    <div>
                      <span>Trader</span>
                    </div>
                  </div>
                </th>
                {authedUser?.authedUser?.master ? (
                  <></>
                ) : (
                  <>
                    <th scope="col">
                      <div className="">
                        <div>
                          <span>Take profit</span>
                        </div>
                      </div>
                    </th>
                    <th scope="col">
                      <div className="">
                        <div>
                          <span>Stop Loss</span>
                        </div>
                      </div>
                    </th>
                  </>
                )}
                <th scope="col">
                  <div className="">
                    <div>
                      <span>Order price executed</span>
                    </div>
                  </div>
                </th>
                <th scope="col">
                  <div className="">
                    <div>
                      <span>Qty </span>
                    </div>
                  </div>
                </th>
                <th scope="col">
                  <div className="">
                    <div>
                      <span>Qty. spent </span>
                    </div>
                  </div>
                </th>
                <th scope="col">
                  <div className="">
                    <div>
                      <span>Status</span>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {currentOrders.map((order) => {
                return (
                  <tr>
                    <td scope="row">
                      <div>
                        <div className="innerDiv">
                          {order?.time ? (
                            <div className="volume text-uppercase">
                              {new Date(order?.time).getHours() > 9 ? "" : "0"}
                              {new Date(order?.time).getHours()}:
                              {new Date(order?.time).getMinutes() > 9
                                ? ""
                                : "0"}
                              {new Date(order?.time).getMinutes()} -{" "}
                              {new Date(order?.time).getDate()}/
                              {new Date(order?.time).getMonth() + 1}/
                              {new Date(order?.time)
                                .getFullYear()
                                .toString()
                                .substring(2)}
                            </div>
                          ) : (
                            <div className="volume text-uppercase">--:--</div>
                          )}
                        </div>
                      </div>
                    </td>
                    <td scope="row">
                      <div>
                        <div className="innerDiv">
                          <div className="volume text-uppercase">
                            {order?.symbol}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td scope="row">
                      <div>
                        <div className="innerDiv">
                          <div className="volume text-uppercase">
                            {order?.side}{" "}
                            <span style={{ textTransform: `none` }}>
                              {order?.type === "LIMIT" ||
                              order?.type === "MARKET"
                                ? ""
                                : "(with TP/SL)"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td scope="row">
                      <div>
                        <div className="innerDiv">
                          <div className="volume text-uppercase">
                            {authedUser?.authedUser?.master
                              ? "Self"
                              : order?.master?.username
                              ? order?.master?.username
                              : "-"}
                          </div>
                        </div>
                      </div>
                    </td>
                    {authedUser?.authedUser?.master ? (
                      <></>
                    ) : (
                      <>
                        <td scope="row">
                          <div>
                            <div className="innerDiv">
                              <div className="volume text-uppercase">
                                {parseFloat(order?.price).toFixed(2)}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td scope="row">
                          <div>
                            <div className="innerDiv">
                              <div className="volume text-uppercase">
                                {parseFloat(order?.stopPrice)
                                  ? parseFloat(order?.stopPrice).toFixed(2)
                                  : "-"}
                              </div>
                            </div>
                          </div>
                        </td>
                      </>
                    )}
                    <td scope="row">
                      <div>
                        <div className="innerDiv">
                          <div className="volume text-uppercase">
                            {parseFloat(order?.cummulativeQuoteQty) &&
                            parseFloat(order?.origQty)
                              ? parseFloat(
                                  order?.cummulativeQuoteQty / order?.origQty
                                ).toFixed(2)
                              : "-"}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td scope="row">
                      <div>
                        <div className="innerDiv">
                          <div className="volume text-uppercase">
                            {parseFloat(order?.origQty)
                              ? `${order?.origQty} ${order?.symbol.substring(
                                  0,
                                  3
                                )}`
                              : "-"}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td scope="row">
                      <div>
                        <div className="innerDiv">
                          <div className="volume text-uppercase">
                            {order?.cummulativeQuoteQty
                              ? `${parseFloat(
                                  order?.cummulativeQuoteQty
                                ).toFixed(2)} ${order?.symbol.substring(3)}`
                              : "-"}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td scope="row">
                      <div>
                        <div className="innerDiv">
                          <div className="volume text-uppercase">
                            {order?.status === "NEW" ? "Pending" : ""}
                            {order?.status === "FILLED" ? "Executed" : ""}
                            {order?.status === "CANCELLED" ? "Cancelled" : ""}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <ReactPaginate
            className="pagination"
            breakLabel="..."
            nextLabel=" >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< "
            renderOnZeroPageCount={null}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
