import React, { useState, useEffect } from "react";

export const Billing = () => {
  const [payments, setPayments] = useState([]);

  const getPaymentHistory = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/users/payment`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((r) => r.json())
      .then((r) => {
        if (r.success) {
          setPayments(r.payments);
        }
      });
  };

  useEffect(() => {
    getPaymentHistory();
  }, []);

  return (
    <div className="container-fluid">
      {/* Content Row */}
      <div className="row">
        <div className={"col-12"}>
          <div className="dashboardMain">
            {/* <div className="exchangeSupportPage orderHisPage"> */}
            <div className="mt-3 exchangeSupportPage">
              <div className="head">
                <h2>Billing History</h2>
              </div>
              <div className="mt-3 connectOpBtn"></div>
              <div className="">
                <div className="">
                  <div className="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">
                            <div className="">
                              <div>
                                <span>Time & Date</span>
                              </div>
                            </div>
                          </th>
                          <th scope="col">
                            <div className="">
                              <div>
                                <span>Tx Hash</span>
                              </div>
                            </div>
                          </th>
                          <th scope="col">
                            <div className="">
                              <div>
                                <span>Link</span>
                              </div>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {payments.map((payment) => {
                          return (
                            <tr key={payment._id}>
                              <td scope="row">
                                <div>
                                  <div className="innerDiv">
                                    {payment?.time ? (
                                      <div className="volume text-uppercase">
                                        {new Date(
                                          payment?.time * 1000
                                        ).getHours() > 9
                                          ? ""
                                          : "0"}
                                        {new Date(
                                          payment?.time * 1000
                                        ).getHours()}
                                        :
                                        {new Date(
                                          payment?.time * 1000
                                        ).getMinutes() > 9
                                          ? ""
                                          : "0"}
                                        {new Date(
                                          payment?.time * 1000
                                        ).getMinutes()}{" "}
                                        -{" "}
                                        {new Date(
                                          payment?.time * 1000
                                        ).getDate()}
                                        /
                                        {new Date(
                                          payment?.time * 1000
                                        ).getMonth() + 1}
                                        /
                                        {new Date(payment?.time * 1000)
                                          .getFullYear()
                                          .toString()
                                          .substring(2)}
                                      </div>
                                    ) : (
                                      <div className="volume text-uppercase">
                                        --:--
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td scope="row">
                                <div>
                                  <div className="innerDiv">
                                    <div className="volume text-uppercase">
                                      {payment?.txHash}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td scope="row">
                                <div>
                                  <div className="innerDiv">
                                    <div className="volume text-uppercase">
                                      <a
                                        href={`https://etherscan.io/tx/${payment.txHash}`}
                                        target="_blank"
                                      >
                                        Link to explorer
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
