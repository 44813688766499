import imageCompression from "browser-image-compression";

const illegalCharacters = ["<", ">", "{", "}", "/", "(", ")"];

export const showBalance = (balance, balanceHide) => {
  return balanceHide ? "***" : balance;
};

export const disableIllegalCharacters = {
  onKeyDown: (e) => {
    if (illegalCharacters.includes(e.key)) {
      e.preventDefault();
    }
  },
};
export const disablePaste = {
  onPaste: (e) => {
    if (
      illegalCharacters.some((char) =>
        e.clipboardData.getData("Text").includes(char)
      )
    ) {
      e.preventDefault();
    }
  },
};

export const compressFile = async (img, formd, key) => {
  if (img) {
    let compressedImg = img;

    if (img.size > 1024 * 1024) {
      try {
        compressedImg = await imageCompression(img, { maxSizeMB: 1 });
      } catch (e) {
        console.log(e);
      }
    }
    formd.append(key ?? "formFile", compressedImg, compressedImg.name);

    return true;
  }

  return false;
};
