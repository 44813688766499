import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../portfolio/portfolio.css";
import { setBalances, setTotalUSDBalance } from "../../redux/ActionCreators";
import AssetsTable from "./assetsTable";
import completeKyc from "../../images/complete-kyc.svg";
import kycCompleted from "../../images/kyc-completed.svg";
import { PaymentFormComp } from "../dashboard/paymentForm";

export default function MyAssets() {
  const dispatch = useDispatch();
  const authedUser = useSelector((s) => s.authedUser);

  useEffect(() => {
    fetch(process.env.REACT_APP_BASE_URL + "/users/balances", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((r) => r.json())
      .then((res) => {
        dispatch(setBalances(res.balances));
        dispatch(setTotalUSDBalance(res.total));
      });
  }, []);

  return (
    <div className="container-fluid">
      {/* Content Row */}
      <div className="row">
        <div className="col-12 mb-4">
          <div className="portfolioPage">
            {!authedUser?.authedUser?.master ? (
              authedUser?.authedUser?.kycVerified &&
              authedUser?.authedUser?.approved ? (
                authedUser?.authedUser?.paid ? (
                  <>
                    <div className="header">
                      <h2>My Assets</h2>
                    </div>
                    <AssetsTable />
                  </>
                ) : (
                  <div className="dashboardMain">
                    <PaymentFormComp />
                    {/* <p>Dear User, </p>
                     <p>Please pay the subscription fees 3000 TENUP in order to start using the services of this platform.</p>
                     <p>Best regards,</p>
                     <p>The Tenup Copy Trading Team.</p> */}
                  </div>
                )
              ) : authedUser.authedUser?.kycName ? (
                <div className="notApproveYet row">
                  <div className="col1 col-xl-7">
                    <h1 className="mt-2">
                      Thank you for submitting your KYC documents!{" "}
                    </h1>
                    <p>
                      Our team will review your submission within 24 hours. If
                      all the details are accurate and meet the requirements, we
                      will approve your KYC and notify you via email.
                    </p>
                    <p>
                      If you have any questions in the meantime, please feel
                      free to contact us at{" "}
                      <b style={{ color: `var(--themeGreen)` }}>
                        support@copytrade.tenup.io
                      </b>
                    </p>
                    <p>Thank you for your patience and cooperation.</p>
                  </div>
                  <div className="col2 col-xl-5 px-lg-0">
                    <div className="imgDiv">
                      <img src={kycCompleted} alt="" className="img-fluid" />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="notApproveYet row">
                  <div className="col1 col-xl-7">
                    <h1 className="mt-2">Dear User,</h1>
                    <p>
                      Please complete your KYC verification to start using the
                      TenUp Copy Trading platform. After submission our team
                      will carefully review your documents. Upon approval, you
                      will receive an email notification with further details.
                    </p>
                    <p>
                      Should you have any inquiries, do not hesitate to contact
                      us at{" "}
                      <b style={{ color: `var(--themeGreen)` }}>
                        support@copytrade.tenup.io
                      </b>
                    </p>
                    <p>We look forward to assisting you!</p>
                    <p>Team TenUp</p>
                  </div>
                  <div className="col2 col-xl-5 px-lg-0">
                    <div className="imgDiv">
                      <img src={completeKyc} alt="" className="img-fluid" />
                    </div>
                  </div>
                </div>
              )
            ) : (
              <>
                <div className="header">
                  <h2>My Assets</h2>
                </div>
                <AssetsTable />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
